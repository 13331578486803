import React, { useRef, useState,useEffect,useMemo,useContext, createContext } from 'react';
import dayjs from 'dayjs';
import {
  AutoComplete,
  Col, Row, 
  Button,
  Dropdown,
  Tooltip,
  Space,
  Spin,
  Table,
  Tag,
  Cascader,
  Checkbox,
  Divider,
  DatePicker,
  TimePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Upload,
  Typography,
} from 'antd';
import type { InputRef,CheckboxRef,MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import {Header,confirmDialog,confirmDialog2,oneButtonConfirmDialog} from '../Common/commonui.tsx'
import {emptyCache,CallAPI,GetData,ConsoleLog,TolocalDateString,webUrl,headerHeight, tabHeight,paddingValue} from '../Common/commonfunction.tsx';
import './Tabs.css';
import {createBrowserRouter,RouterProvider,useNavigate } from "react-router-dom";
import { DownOutlined,EllipsisOutlined } from '@ant-design/icons';

const textwidth = 200;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Text, Link,Paragraph } = Typography;
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
}

interface DataType {
  key : string;
  ActualDestination:string;
  ActualFlightNo:string;
  ActualFlightSchedule:string;
  ActualOrderPickUpDatetime:string;
  ActualPickup:string;
  ActualStopOver1:string;
  ActualStopOver2:string;
  ActualStopOver3:string;
  AdditionalCharge:string;
  Address:string;
  AssignDriverID:string;
  AssignDriverID2:string;
  AssignVehicleID:string;
  AssignVehicleID2:string;
  DriverName:string;
  DriverMobile:string;
  BookingDatetime:string;
  CarType:string;
  ContactName1:string;
  ContactName2:string;
  ContactTelNo1:string;
  ContactTelNo2:string;
  CreatedBy:string;
  CreatedDatetime:string;
  CustomerName:string;
  CustomerType:string;
  Destination:string;
  DriverDropOffDatetime:string;
  DriverDropOffDatetime2:string;
  DriverPickUpDatetime:string;
  DriverPickUpDatetime2:string;
  DriverStartWaitDatetime:string;
  DriverStartWaitDatetime2:string;
  DriverWaitDuration:string;
  DriverWaitDuration2:string;
  EditingBy:string;
  EditingDatetime:string;
  EformOrderNo:string;
  EstimatedOrderDuration:string;
  FlightNo:string;
  FlightSchedule:string;
  FlightStatus:string;
  FirstReminder:string;
  HaveMeetNGreet:string;
  HavePhoto:string;
  HireRequest:string;
  ID:string;
  Journey:string;
  NoOfLuggage:string;
  NoOfPassenger:string;
  OrderCharge:string;
  OrderNo:string;
  OrderPickUpDatetime:string;
  ParkingCharge:string;
  ParkingCharge2:string;
  PassengerName:string;
  PassengerTelNo:string;
  Pickup:string;
  ReadyToUpload:string;
  Remarks:string;
  ServiceProvider:string;
  SpecialInstructions:string;
  Status:string;
  StopOver1:string;
  StopOver2:string;
  StopOver3:string;
  Title:string;
  TunnelCharge:string;
  TunnelCharge2:string;
  TunnelString:string;
  TunnelString2:string;
  UpdatedBy:string;
  UpdatedDatetime:string;
  VendorDriver:string;
  VendorName:string;
  SMSSend:string;
  SMSStatusPassenger:string;
  SMSStatusContact:string;
  SMSRetry:string;
  MLSUpdateAlert:string;
}
//`SMSSend`,`SMSStatusPassenger`,`SMSStatusContact`,`SMSRetry`
//defaultSortOrder: 'descend',
interface SelectOption {
  label: string;
  value: string;
}
export default function OrderTable(arg) {
  const [loading,SetLoading] = useState(true);
  const [maxModalSize, setMaxModalSize] = useState(1200);
  const [OrderList,SetOrderList] = useState<DataType[]>([]);
  const [OrderSearchedList,SetOrderSearchedList] = useState<DataType[]>([]);
  const [IsErrorDialog,SetIsErrorDialog] = useState(false);
  const [SelectedDate,SetSelectedDate] = useState(localStorage.getItem('DCHSelectedOrderSummaryDate'));
  const [orderStatusReferenceRawList,SetOrderStatusReferenceRawList] = useState(
    [
      {"StatusName":"Acknowledge","DisplayName":"Acknowledge","Type":"In-Progress","key":0},
      {"StatusName":"Cancel","DisplayName":"Cancelled","Type":"Cancel","key":1},
      {"StatusName":"CancelUploaded","DisplayName":"Cancelled Uploaded","Type":"Uploaded","key":2},
      {"StatusName":"CancelUploadFail","DisplayName":"Cancelled Upload Fail","Type":"Uploaded","key":3},
      {"StatusName":"Confirm","DisplayName":"Confirm","Type":"Outstanding","key":4},
      {"StatusName":"Dispatched","DisplayName":"Dispatched","Type":"In-Progress","key":5},
      {"StatusName":"Dropoff","DisplayName":"Completed","Type":"Complete","key":6},
      {"StatusName":"DropoffUploaded","DisplayName":"Completed Uploaded","Type":"Uploaded","key":7},
      {"StatusName":"DropoffUploadFail","DisplayName":"Completed Upload Fail","Type":"Uploaded","key":8},
      {"StatusName":"Noshow","DisplayName":"No Show","Type":"Noshow","key":9},
      {"StatusName":"NoshowUploaded","DisplayName":"No Show Uploaded","Type":"Uploaded","key":10},
      {"StatusName":"NoshowUploadFail","DisplayName":"No Show Upload Fail","Type":"Uploaded","key":11},
      {"StatusName":"NotAvailable","DisplayName":"Not Available","Type":"Complete","key":12},
      {"StatusName":"Open","DisplayName":"Open","Type":"Outstanding","key":13},
      {"StatusName":"Pickup","DisplayName":"Pick-up","Type":"In-Progress","key":14},
      {"StatusName":"TBC","DisplayName":"TBC","Type":"In-Progress","key":15},
      {"StatusName":"Waiting","DisplayName":"Waiting","Type":"In-Progress","key":16}
    ]
  )
  const [orderStatusReferenceIndexMap,SetOrderStatusReferenceIndexMap] = useState({})
  const typeString = arg["arg"];
  const resize = () => {
      const maxSize = window.innerHeight;
      setMaxModalSize(maxSize);
      //ConsoleLog(maxSize)
      
  };

  const [OrderDetailPageStatus,SetOrderDetailPageStatus] = useState({});
  const [DriverFilterList,SetDriverFilterList] = useState<SelectOption[]>([]);
  const [DriverDetailList,SetDriverDetailList] = useState([]);
  const [DriverFilterDetailList,SetDriverFilterDetailList] = useState<any[]>([]);
  const [VehicleList,SetVehicleList] = useState<SelectOption[]>([]);
  const [VehicleFilterList,SetVehicleFilterList] = useState<SelectOption[]>([]);
  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const [VehicleID,SetVehicleID] = useState("");
  const [DriverID,SetDriverID] = useState("");
  const [DriverName,SetDriverName] = useState("");
  const [DriverMobile,SetDriverMobile] = useState("");
  const [ServiceProvider,SetServiceProvider] = useState("");
  const [Vendor,SetVendor] = useState("");

  const [TabIndex,SetTabIndex] = useState("0");
  const [OrderType,SetOrderType] = useState("");
  const [UploadOrderCheckList,SetUploadOrderCheckList] = useState([]);

  const [ServiceProviderList,SetServiceProviderList] = useState([
    {label:"",value:""},
    {label:"DCH",value:"DCH"},
    {label:"Consignment",value:"Consignment"},
  ])
  const [ServiceVenderList,SetServiceVenderList] = useState<SelectOption[]>([])
  const [ServiceVenderListfiltered,SetServiceVenderListfiltered] = useState<SelectOption[]>([])
  const tableRowHeight = 75

  const [AuditTrailContent,setAuditTrailContent] = useState([])
  const [AuditTrailTitle,setAuditTrailTitle] = useState("AuditTrail")
  const [IsAuditTrail,setIsAuditTrail] = useState(false)

  const dialogAssignDriver = (
    <Modal title={""} open={isModalOpen1} onOk={()=>{}} onCancel={()=>{setIsModalOpen1(false)}} confirmLoading={false}
    width={450}
    bodyStyle={{height:210}}
    footer={[

      <Button key="back" onClick={()=>{setIsModalOpen1(false)}}>
        Cancel
      </Button>,

      <Button key="submit" type="primary" loading={false} onClick={
            ()=>{
              confirmDialog("Dispatch?","",
                ()=>{
                    UpdateOrder(OrderDetailPageStatus["OrderNo"],VehicleID,DriverID,DriverName,DriverMobile,ServiceProvider,Vendor,"Dispatched")
                    setIsModalOpen1(false);
                    if(OrderDetailPageStatus["SMSEnable"] === "1"){
                        ResendOrderSMS(OrderDetailPageStatus["OrderNo"],"Redispatch")
                    }
                },
                ()=>{
                  //setIsModalOpen1(false);
                })
            }
          }
        >
        Assign
      </Button>,
    ]}

    >
      <div style={{height:210,overflow:"hidden",overflowX:"hidden"}}>
        <Row>
          <Col span={11}><Text style={{  paddingTop: 5,width: "95%" } } >Service Provider</Text></Col>
          <Col span={13}>
            <AutoComplete disabled={false} 
              value={ServiceProvider}
              options={
                  ServiceProviderList
              }
              style={{ width: "90%" }}
              onSelect={(e)=>{
                SetServiceProvider(e)
                if(e === "DCH"){
                  SetServiceVenderListfiltered([])
                  SetVehicleFilterList(VehicleList)
                  SetVendor("")
                }else if(e === "Consignment"){
                    SetServiceVenderListfiltered(ServiceVenderList)
                    SetVehicleFilterList([])
                }
                var selectItemList:SelectOption[] = []
                var list = []
                for(var k = 0;k<DriverDetailList.length;k++){//Name DriverID
                  if(e === "DCH" && DriverDetailList[k]['ServiceProvider'] === "DCH"){
                      ConsoleLog(1)
                      selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
                      list.push(DriverDetailList[k])
                  }else if(e === "Consignment" && DriverDetailList[k]['ServiceProvider'] === "Consignment" && (DriverDetailList[k]['Vendor'] === Vendor || Vendor === "")){
                      ConsoleLog(2)
                      selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
                      list.push(DriverDetailList[k])
                  }
                }
                SetDriverFilterDetailList(list)
                SetDriverFilterList(selectItemList)
                SetDriverName("")
                SetDriverID("")
                SetVehicleID("")
                SetDriverMobile("")
              }}
              onChange={(e)=>{
                SetServiceProvider(e)
                if(e === "DCH"){
                  SetServiceVenderListfiltered([])
                  SetVehicleFilterList(VehicleList)
                  SetVendor("")
                }else if(e === "Consignment"){
                    SetServiceVenderListfiltered(ServiceVenderList)
                    SetVehicleFilterList([])
                }
                var selectItemList:SelectOption[] = []
                var list = []
                for(var k = 0;k<DriverDetailList.length;k++){//Name DriverID
                  if(e === "DCH" && DriverDetailList[k]['ServiceProvider'] === "DCH"){
                      ConsoleLog(11)
                      selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
                      list.push(DriverDetailList[k])
                  }else if(e === "Consignment" && DriverDetailList[k]['ServiceProvider'] === "Consignment" && (DriverDetailList[k]['Vendor'] === Vendor || Vendor === "")){
                      ConsoleLog(22)
                      selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
                      list.push(DriverDetailList[k])
                  }
                }
                SetDriverFilterDetailList(list)
                SetDriverFilterList(selectItemList)
                SetDriverName("")
                SetDriverID("")
                SetVehicleID("")
                SetDriverMobile("")
              }}
              filterOption={(inputValue, option) =>
                  option!.label.toString().toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
                  }
              placeholder=""
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 10 } } >
          <Col span={11}><Text style={{  paddingTop: 5,width: "95%" } } >Vendor</Text></Col>
          <Col span={13}>
          <AutoComplete disabled={false} 
              value={Vendor}
              options={
                ServiceVenderListfiltered
              }
              style={{ width: "90%" }}
              onSelect={(e)=>{
                SetVendor(e)
                var selectItemList:SelectOption[] = []
                var list = []
                for(var k = 0;k<DriverDetailList.length;k++){//Name DriverID
                  if(ServiceProvider === "DCH" && DriverDetailList[k]['ServiceProvider'] === "DCH"){
                      ConsoleLog(1)
                      selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
                      list.push(DriverDetailList[k])
                  }else if(ServiceProvider === "Consignment" && DriverDetailList[k]['ServiceProvider'] === "Consignment" && (DriverDetailList[k]['Vendor'] === e || e === "" )){// || DriverDetailList[k]['Vendor'] === ""
                      ConsoleLog(2)
                      selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
                      list.push(DriverDetailList[k])
                  }
                }
                SetDriverFilterDetailList(list)
                SetDriverFilterList(selectItemList)
                SetDriverName("")
                SetDriverID("")
                SetVehicleID("")
                SetDriverMobile("")
              }}
              onChange={(e)=>{
                SetVendor(e)
                var selectItemList:SelectOption[] = []
                var list = []
                for(var k = 0;k<DriverDetailList.length;k++){//Name DriverID
                  if(ServiceProvider === "DCH" && DriverDetailList[k]['ServiceProvider'] === "DCH"){
                      ConsoleLog(1)
                      selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
                      list.push(DriverDetailList[k])
                  }else if(ServiceProvider === "Consignment" && DriverDetailList[k]['ServiceProvider'] === "Consignment" && (DriverDetailList[k]['Vendor'] === e || e === "" )){// || DriverDetailList[k]['Vendor'] === ""
                      ConsoleLog(2)
                      selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
                      list.push(DriverDetailList[k])
                  }
                }
                SetDriverFilterDetailList(list)
                SetDriverFilterList(selectItemList)
                SetDriverName("")
                SetDriverID("")
                SetVehicleID("")
                SetDriverMobile("")
              }}
              filterOption={(inputValue, option) =>
                  option!.label.toString().toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
                  }
              placeholder=""
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 10 } } >
            <Col span={11}><Text style={{  paddingTop: 5,width: "95%" } } >Assigned Driver</Text></Col>
            <Col span={13}>
            <AutoComplete style={{ width: "90%" } } disabled={false}
                value = {DriverName} 
                
                onSelect={(e)=>{
                    SetDriverID(e);
                    ConsoleLog(e);
                    const listsize = DriverFilterDetailList.length
                    for(var k = 0 ; k<listsize ; k++){
                        if(e===DriverFilterDetailList[k]["DriverID"]){
                            SetDriverName(DriverFilterDetailList[k]["Name"])
                            SetDriverMobile(DriverFilterDetailList[k]["DriverTelNo"])
                            break
                        }
                    }
                }}

                onChange={(e)=>{
                    SetDriverName(e);
                    const listsize = DriverFilterDetailList.length
                    for(var k = 0 ; k<listsize ; k++){
                        if(e===DriverFilterDetailList[k]["Name"]){
                            SetDriverID(DriverFilterDetailList[k]["DriverID"])
                            SetDriverMobile(DriverFilterDetailList[k]["DriverTelNo"])
                            break
                        }
                        if((k) === (listsize -1 )){
                            SetDriverID("")
                            SetDriverMobile("")
                        }
                    }
                    if(DriverName === ""){
                      SetDriverID("")
                      SetDriverMobile("")
                    }
                }}

                filterOption={(inputValue, option) =>
                    option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                placeholder=""
                //onSelect={(e)=>{ConsoleLog(e)}}
                options={DriverFilterList}/>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 } } >
            <Col span={11}><Text style={{  paddingTop: 5,width: "95%" } } >Driver Mobile</Text></Col>
            <Col span={13}>
            <InputNumber controls={false} value={DriverMobile} onChange={(e)=>{
                            SetDriverMobile(e!)
                        }} style={{ width: "90%" }}/>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 } } >
            <Col span={11}><Text style={{  paddingTop: 5,width: "95%" } } >Assigned Vehicle</Text></Col>
            <Col span={13}>
            <AutoComplete style={{ width: "90%" }}  disabled={false}
                value = {VehicleID} 
                onSelect={(e)=>{
                    SetVehicleID(e)
                    //OrderDetailPageStatus.AssignVehicleID = (e)
                }}
                onChange={(e)=>{
                    SetVehicleID(e)
                    //OrderDetailPageStatus.AssignVehicleID = (e)
                }}
                options={VehicleFilterList}/>
            </Col>
        </Row>
      </div>
  </Modal>
    );
//top:12,left:8
//position:"absolute",top:0,left:0

const orderSunmmaryMenu = (record:DataType,content)=>{
  return (
    <div style={{height:tableRowHeight,background:((parseInt(record.key)%2 === 0)?"#A2D88A88":"#FFFFFF88")}}>
      {content}
    </div>
  )
  const Dispatch = {
    label: 'Dispatch',
    key: '1',
    onClick:(e)=>{
      SetOrderDetailPageStatus(record)
      var selectItemList:SelectOption[] = []
      var list = []
      for(var k = 0;k<DriverDetailList.length;k++){//Name DriverID
        if(record["ServiceProvider"] === "DCH" && DriverDetailList[k]['ServiceProvider'] === "DCH"){
            ConsoleLog(1)
            selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
            list.push(DriverDetailList[k])
        }else if(record["ServiceProvider"] === "Consignment" && DriverDetailList[k]['ServiceProvider'] === "Consignment" && (DriverDetailList[k]['Vendor'] === record["VendorName"] || record["VendorName"] === "")){
            ConsoleLog(2)
            selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
            list.push(DriverDetailList[k])
        }
      }
      SetDriverFilterDetailList(list)
      SetDriverFilterList(selectItemList)
      SetVehicleID(record["AssignVehicleID"])
      SetDriverID(record["AssignDriverID"])
      SetDriverName(record["DriverName"])
      SetDriverMobile(record["DriverMobile"])
      setIsModalOpen1(true)
    },
  }  
  const reDispatch = {
    label: 'Re Dispatch',
    key: '2',
    onClick:(e)=>{
      SetOrderDetailPageStatus(record)
      var selectItemList:SelectOption[] = []
      var list = []
      for(var k = 0;k<DriverDetailList.length;k++){//Name DriverID
        if(record["ServiceProvider"] === "DCH" && DriverDetailList[k]['ServiceProvider'] === "DCH"){
            ConsoleLog(1)
            selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
            list.push(DriverDetailList[k])
        }else if(record["ServiceProvider"] === "Consignment" && DriverDetailList[k]['ServiceProvider'] === "Consignment" && (DriverDetailList[k]['Vendor'] === record["VendorName"] || record["VendorName"] === "")){
            ConsoleLog(2)
            selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
            list.push(DriverDetailList[k])
        }
      }
      SetDriverFilterDetailList(list)
      SetDriverFilterList(selectItemList)
      SetVehicleID(record["AssignVehicleID"])
      SetDriverID(record["AssignDriverID"])
      SetDriverName(record["DriverName"])
      SetDriverMobile(record["DriverMobile"])
      setIsModalOpen1(true)
    },
  }
  const Complete = {
    label: 'Complete',
    key: '3',
    onClick:(e)=>{
      confirmDialog2(`Change Order Status to "Completed" ?`,"","Yes","Cancel",
      ()=>{
        UpdateOrderAssignDriver(record.OrderNo,record.AssignVehicleID,record.AssignDriverID,record.DriverName,record.DriverMobile,"Dropoff")
      },
      ()=>{
        //setIsModalOpen1(false);
      })
    },
  }
  const Cancel = {
    label: 'Cancel',
    key: '4',
    onClick:(e)=>{
      confirmDialog2(`Change Order Status to "Cancelled" ?`,"","Yes","Cancel",
      ()=>{
        UpdateOrderAssignDriver(record.OrderNo,record.AssignVehicleID,record.AssignDriverID,record.DriverName,record.DriverMobile,"Cancel")
      },
      ()=>{
        //setIsModalOpen1(false);
      })
    },
  }
  const Noshow = {
    label: 'Noshow',
    key: '5',
    onClick:(e)=>{
      confirmDialog2(`Change Order Status to "No show" ?`,"","Yes","Cancel",
      ()=>{
        UpdateOrderAssignDriver(record.OrderNo,record.AssignVehicleID,record.AssignDriverID,record.DriverName,record.DriverMobile,"Noshow")
      },
      ()=>{
        //setIsModalOpen1(false);
      })
    },
  }
  if(
    record.Status === "Dropoff" ||
    record.Status === "Noshow" ||
    record.Status === "Cancel"
  ){
    return(
      <div style={{height:tableRowHeight,background:((parseInt(record.key)%2 === 0)?"#A2D88A88":"#FFFFFF88")}}>
      <Dropdown menu={{
        items:
        [
          Complete,
          Cancel,
          Noshow
        ]
        }} trigger={['contextMenu']}>
          {content}
      </Dropdown>
      </div>
    )
  }else if(record.Status === "Pickup"){
    return(
      <div style={{height:tableRowHeight,background:((parseInt(record.key)%2 === 0)?"#A2D88A88":"#FFFFFF88")}}>
      <Dropdown menu={{
        items:
        [
          Complete,
        ]
        }} trigger={['contextMenu']}>
          {content}
      </Dropdown>
      </div>
    );
  }else if(record.Status === "Open"){
    return(
      <div style={{height:tableRowHeight,background:((parseInt(record.key)%2 === 0)?"#A2D88A88":"#FFFFFF88")}}>
      <Dropdown menu={{
        items:
        [
          Dispatch,
          Cancel
        ]
        }} trigger={['contextMenu']}>
          {content}
      </Dropdown>
      </div>
    );
  }else if(
    record.Status === "Dispatched" ||
    record.Status === "Acknowledge" ||
    record.Status === "Waiting"
  ){
    return(
      <div style={{height:tableRowHeight,background:((parseInt(record.key)%2 === 0)?"#A2D88A88":"#FFFFFF88")}}>
      <Dropdown menu={{
        items:
        [
          reDispatch,
          Complete,
          Cancel,
          Noshow
        ]
        }} trigger={['contextMenu']}>
          {content}
      </Dropdown>
      </div>
    );
  }else{
    return(
      <div style={{height:tableRowHeight,background:((parseInt(record.key)%2 === 0)?"#A2D88A88":"#FFFFFF88")}}>
      <Dropdown menu={{
        items:
        [
        ]
        }} trigger={['contextMenu']}>
          {content}
      </Dropdown>
      </div>
    );
  }
}
const orderSunmmaryMenu2 = (record:DataType,content)=>{
  const Dispatch = {
    label: 'Dispatch',
    key: '1',
    onClick:(e)=>{
      SetOrderDetailPageStatus(record)
      var selectItemList:SelectOption[] = []
      var list = []
      for(var k = 0;k<DriverDetailList.length;k++){//Name DriverID
        if(record["ServiceProvider"] === "DCH" && DriverDetailList[k]['ServiceProvider'] === "DCH"){
            ConsoleLog(1)
            selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
            list.push(DriverDetailList[k])
        }else if(record["ServiceProvider"] === "Consignment" && DriverDetailList[k]['ServiceProvider'] === "Consignment" && (DriverDetailList[k]['Vendor'] === record["VendorName"] || record["VendorName"] === "")){
            ConsoleLog(2)
            selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
            list.push(DriverDetailList[k])
        }
      }
      SetDriverFilterDetailList(list)
      SetDriverFilterList(selectItemList)
      SetVehicleID(record["AssignVehicleID"])
      SetDriverID(record["AssignDriverID"])
      SetDriverName(record["DriverName"])
      SetDriverMobile(record["DriverMobile"])
      SetServiceProvider(record["ServiceProvider"])
      SetVendor(record["VendorName"])
      setIsModalOpen1(true)
    },
  }  
  const reDispatch = {
    label: 'Re Dispatch',
    key: '2',
    onClick:(e)=>{
      SetOrderDetailPageStatus(record)
      var selectItemList:SelectOption[] = []
      var list = []
      for(var k = 0;k<DriverDetailList.length;k++){//Name DriverID
        if(record["ServiceProvider"] === "DCH" && DriverDetailList[k]['ServiceProvider'] === "DCH"){
            ConsoleLog(1)
            selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
            list.push(DriverDetailList[k])
        }else if(record["ServiceProvider"] === "Consignment" && DriverDetailList[k]['ServiceProvider'] === "Consignment" && (DriverDetailList[k]['Vendor'] === record["VendorName"] || record["VendorName"] === "")){
            ConsoleLog(2)
            selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
            list.push(DriverDetailList[k])
        }
      }
      SetDriverFilterDetailList(list)
      SetDriverFilterList(selectItemList)
      SetVehicleID(record["AssignVehicleID"])
      SetDriverID(record["AssignDriverID"])
      SetDriverName(record["DriverName"])
      SetDriverMobile(record["DriverMobile"])
      SetServiceProvider(record["ServiceProvider"])
      SetVendor(record["VendorName"])
      setIsModalOpen1(true)
    },
  }
  const Complete = {
    label: 'Complete',
    key: '3',
    onClick:(e)=>{
      confirmDialog2(`Change Order Status to "Completed" ?`,"","Yes","Cancel",
      ()=>{
        UpdateOrderAssignDriver(record.OrderNo,record.AssignVehicleID,record.AssignDriverID,record.DriverName,record.DriverMobile,"Dropoff")
      },
      ()=>{
        //setIsModalOpen1(false);
      })
    },
  }
  const Cancel = {
    label: 'Cancel',
    key: '4',
    onClick:(e)=>{
      confirmDialog2(`Change Order Status to "Cancelled" ?`,"","Yes","Cancel",
      ()=>{
        UpdateOrderAssignDriver(record.OrderNo,record.AssignVehicleID,record.AssignDriverID,record.DriverName,record.DriverMobile,"Cancel")
      },
      ()=>{
        //setIsModalOpen1(false);
      })
    },
  }
  const Noshow = {
    label: 'Noshow',
    key: '5',
    onClick:(e)=>{
      confirmDialog2(`Change Order Status to "No show" ?`,"","Yes","Cancel",
      ()=>{
        UpdateOrderAssignDriver(record.OrderNo,record.AssignVehicleID,record.AssignDriverID,record.DriverName,record.DriverMobile,"Noshow")
      },
      ()=>{
        //setIsModalOpen1(false);
      })
    },
  }
  if(
    record.Status === "Dropoff" ||
    record.Status === "Noshow" ||
    record.Status === "Cancel"
  ){
    return(
      <div style={{background:((parseInt(record.key)%2 === 0)?"#A2D88A00":"#FFFFFF00")}}>
      <Dropdown menu={{
        items:
        [
          Complete,
          Cancel,
          Noshow
        ]
        }} trigger={['click']}>
          {content}
      </Dropdown>
      </div>
    )
  }else if(record.Status === "Pickup"){
    return(
      <div style={{background:((parseInt(record.key)%2 === 0)?"#A2D88A00":"#FFFFFF00")}}>
      <Dropdown menu={{
        items:
        [
          Complete,
        ]
        }} trigger={['click']}>
          {content}
      </Dropdown>
      </div>
    );
  }else if(record.Status === "Open"){
    return(
      <div style={{background:((parseInt(record.key)%2 === 0)?"#A2D88A00":"#FFFFFF00")}}>
      <Dropdown menu={{
        items:
        [
          Dispatch,
          Cancel
        ]
        }} trigger={['click']}>
          {content}
      </Dropdown>
      </div>
    );
  }else if(
    record.Status === "Dispatched" ||
    record.Status === "Acknowledge" ||
    record.Status === "Waiting"
  ){
    return(
      <div style={{background:((parseInt(record.key)%2 === 0)?"#A2D88A00":"#FFFFFF00")}}>
      <Dropdown menu={{
        items:
        [
          reDispatch,
          Complete,
          Cancel,
          Noshow
        ]
        }} trigger={['click']}>
          {content}
      </Dropdown>
      </div>
    );
  }else{
    return(
      <div style={{background:((parseInt(record.key)%2 === 0)?"#A2D88A00":"#FFFFFF00")}}>
      <Dropdown menu={{
        items:
        [
        ]
        }} trigger={['click']}>
          <DownOutlined />
      </Dropdown>
      </div>
    );
  }
}
const columns2: ColumnsType<DataType> = [
    {
      title: '　Order No.\n　E-form Order No.',
      dataIndex: 'OrderNo',
      key: 'OrderNo',
      sorter: (a, b) =>  a.OrderNo.localeCompare(b.OrderNo),
      render: (_:any,record:DataType) => 
      orderSunmmaryMenu(record,(
      <div style={{height:tableRowHeight,width:"100%"}}>
        <Row><Text style={{width:"98%",fontSize:14,resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{" "+record.OrderNo}</Text></Row>
        <Row><Text style={{color:"#FF0000",fontSize:13,width:"98%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{" "+record.EformOrderNo}</Text></Row>
        {!(record.Status?.includes("fail") || record.Status?.includes("Upload") || record.Status === "Dropoff" || record.Status === "Noshow" || record.Status === "Cancel")?(
        <Row style={{marginTop:5,width:"100%"}}>
        <Col span={12}>
          {(record.SMSStatusPassenger.toString().replace(/103/g,"") !== "" || record.PassengerTelNo.toString() !== "")?
          (<Button type="primary"size="small"style={{
            width:"96%",
            background:(record.SMSStatusPassenger.toString() === "100")?("#00B400"):
            (
              (record.SMSStatusPassenger.toString() === "Resend" )?"#777777":
              (record.SMSStatusPassenger.toString() === ""?"#1677ff":"#FF0000")
            )
          }}
          onClick={
            ()=>{
              if(record.SMSStatusPassenger.toString().replace(/103/g,"").replace(/100/g,"").replace(/Resend/g,"") !== "" || true){
              confirmDialog2("Resend SMS to Passenger?","","Yes","Cancel",
                ()=>{
                  ResendOrderSMS(record.OrderNo,"Passenger");
                },
                ()=>{
                  
                });}
              }
          }
          >Pax SMS</Button>):(<Text style={{width:"96%"}}></Text>)}
        </Col>
        <Col span={12}>
          {(record.SMSStatusContact.toString().replace(/103/g,"") !== "" || record.ContactTelNo2.toString() !== "")?
          (<Button type="primary"size="small"style={{
            width:"96%",
            background:(record.SMSStatusContact.toString() === "100")?("#00B400"):
            (
              (record.SMSStatusContact.toString() === "Resend" )?"#777777":
              (record.SMSStatusContact.toString() === ""?"#1677ff":"#FF0000")
            )
          }}
          onClick={
            ()=>{
              if(record.SMSStatusContact.toString().replace(/103/g,"").replace(/100/g,"").replace(/Resend/g,"") !== "" || true){
              confirmDialog2("Resend SMS to Contact?","","Yes","Cancel",
                ()=>{
                  ResendOrderSMS(record.OrderNo,"Contact");
                },
                ()=>{
                  
                });}
              }
          }
          >Con SMS</Button>):(<Text style={{width:"96%"}}></Text>)}
        </Col>
        </Row>):(<div></div>)}
      </div>)),
      width:"9%"
    },
    {
      title: '　Pickup',
      dataIndex: 'ActualOrderPickUpDatetime',
      key: 'ActualOrderPickUpDatetime',
      render: (_:any,record:DataType) => 
        orderSunmmaryMenu(record,(
        //<TextArea rows={3} style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}} bordered={false} value={((record.ActualOrderPickUpDatetime as string).length >= 16?record.ActualOrderPickUpDatetime.substring(10,16):"")+""}/>
        <div>
          <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{((record.ActualOrderPickUpDatetime as string).length >= 16?record.ActualOrderPickUpDatetime.substring(10,16):"")}</div>
      </div>
        )),
      sorter: (a, b) => a.ActualOrderPickUpDatetime.localeCompare(b.ActualOrderPickUpDatetime),
      width:"5%"
    },
    {
      title: '　Status',
      key: 'Status',
      dataIndex: 'Status',
      sorter: (a, b) => a.Status.localeCompare(b.Status),
      render: (_:any,record:DataType) => 
      orderSunmmaryMenu(record,(
      <div>
      {<TextArea rows={3} style={{paddingLeft:20,width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap",color:record.Status!.toString()==='Cancel'?"#FF0000":"#000000"}} bordered={false} value={orderStatusReferenceIndexMap[record.Status] + "\n" +
       `${record.Status!.toString() === 'Waiting' ?record.DriverStartWaitDatetime!.substring(11,16):""}` +
       `${record.Status!.toString() === 'Pickup' ?record.DriverPickUpDatetime!.substring(11,16):""}` +
       `${record.Status!.toString().includes('Dropoff') ?record.DriverDropOffDatetime!.substring(11,16):""}`
      }/>}
      {/* <div style={{paddingLeft:20,width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap",color:record.Status!.toString()==='Cancel'?"#FF0000":"#000000"}}>{orderStatusReferenceIndexMap[record.Status]}</div>
      <div style={{paddingLeft:20,width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap",color:record.Status!.toString()==='Cancel'?"#FF0000":"#000000"}}>{
        `${record.Status!.toString() === 'Waiting' ?record.DriverStartWaitDatetime!.substring(11,16):""}` +
        `${record.Status!.toString() === 'Pickup' ?record.DriverPickUpDatetime!.substring(11,16):""}` +
        `${record.Status!.toString().includes('Dropoff') ?record.DriverDropOffDatetime!.substring(11,16):""}`
      }</div>
      {(record.Status!.toString() === 'Dropoff' ||record.Status!.toString() === 'Cancel' ||record.Status!.toString() === 'Noshow')?(<Checkbox style={{position:"absolute",top:5,left:2}} onChange={(e)=>{UploadOrderCheckList[record.OrderNo]=e.target.checked;SetUploadOrderCheckList(UploadOrderCheckList);ConsoleLog(record.OrderNo+":"+e.target.checked);ConsoleLog(UploadOrderCheckList[record.OrderNo])}}></Checkbox>):(null)} */}
      </div>
      )),
      filterSearch: false,
      width:"7%"
    },
    {
      title: '　Flight Info',
      key: 'ActualFlightNo',
      dataIndex: 'ActualFlightNo',
      render: (_:any,record:DataType) => 
      orderSunmmaryMenu(record,(
      <TextArea rows={3} style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}} bordered={false} value={(record.ActualFlightNo === ""?"---":record.ActualFlightNo)+"\n"+
      ((record.ActualFlightSchedule ==="0001-01-01 00:00:00"?"":(record.ActualFlightSchedule.length>=16?record.ActualFlightSchedule.substring(11,16):record.ActualFlightSchedule))) + "\n" +
      (record.FlightStatus === "---"?"":record.FlightStatus)}/>
      // <div>
      //   <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{(record.ActualFlightNo === ""?"---":record.ActualFlightNo)}</div>
      //   <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{((record.ActualFlightSchedule ==="0001-01-01 00:00:00"?"":(record.ActualFlightSchedule.length>=16?record.ActualFlightSchedule.substring(11,16):record.ActualFlightSchedule)))}</div>
      //   <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{(record.FlightStatus === "---"?"":record.FlightStatus)}</div>
      // </div>
      )),
      sorter: (a, b) => a.FlightNo.localeCompare(b.FlightNo),
      width:"7%"
    },
    {
      title: '　Journey\n　Limo Class',
      key: 'Journey',
      dataIndex: 'Journey',
      sorter: (a, b) => a.Journey.localeCompare(b.Journey),
      render: (_:any,record:DataType) => 
      orderSunmmaryMenu(record,(
      <TextArea rows={3} style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}} bordered={false} value={record.Journey+"\n"+record.CarType}/>
      // <div>
      //   <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.Journey}</div>
      //   <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.CarType}</div>
      // </div>
      )),
      width:"8%"
    },
    {
      title: '　Assigned Car\n　Assigned Driver',
      key: 'AssignVehicleID',
      dataIndex: 'AssignVehicleID',
      sorter: (a, b) => a.AssignVehicleID.localeCompare(b.AssignVehicleID),
      render: (_:any,record:DataType) => 
      orderSunmmaryMenu(record,(
      <TextArea rows={3} style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}} bordered={false} value={record.AssignVehicleID+"\n"+record.DriverName+"\n"+record.DriverMobile}/>
      // <div>
      //   <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.AssignVehicleID}</div>
      //   <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.DriverName}</div>
      //   <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.DriverMobile}</div>
      // </div>
      )),
      width:"8%"
    },
    {
      title: '　Passenger\n　Mobile No',
      key: 'PassengerName',
      dataIndex: 'PassengerName',
      sorter: (a, b) => a.PassengerName.localeCompare(b.PassengerName),
      render: (_:any,record:DataType) => 
      orderSunmmaryMenu(record,(
      <TextArea rows={3} style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}} bordered={false} value={record.PassengerName+"\n"+record.PassengerTelNo}/>
      // <div>
      //   <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.PassengerName}</div>
      //   <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.PassengerTelNo}</div>
      // </div>
      )),
      width:"8%"
    },
    {
      title: '　Pick-up Location',
      key: 'ActualPickup',
      dataIndex: 'ActualPickup',
      sorter: (a, b) => a.ActualPickup.localeCompare(b.ActualPickup),
      render: (_:any,record:DataType) => 
      orderSunmmaryMenu(record,(
      <TextArea rows={3} style={{width:"98%",resize:"none",overflow:"hidden"}} bordered={false} value={record.ActualPickup}/>
      //<div style={{width:"98%",resize:"none",overflow:"hidden"}}>{record.ActualPickup}</div>
      )),
      width:"13%"
    },
    {
      title: '　Drop-off Location',
      key: 'ActualDestination',
      dataIndex: 'ActualDestination',
      sorter: (a, b) => a.ActualDestination.localeCompare(b.ActualDestination),
      render: (_:any,record:DataType) => 
      orderSunmmaryMenu(record,(
      <TextArea rows={3} style={{width:"98%",resize:"none",overflow:"hidden"}} bordered={false} value={record.ActualDestination}/>
      //<div style={{width:"98%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.ActualDestination}</div>
      )),
      width:"13%"
    },
    {
      title: '　Stop Over1',
      key: 'ActualStopOver1',
      dataIndex: 'ActualStopOver1',
      sorter: (a, b) => a.ActualStopOver1.localeCompare(b.ActualStopOver1),
      render: (_:any,record:DataType) => 
      orderSunmmaryMenu(record,(
      <Tooltip overlayStyle={{minWidth:450}} placement="left" title={
          <>
            <div style={{color:"#FFFFFF",fontWeight:"bold"}}><u>Stop Over1:</u></div>
            <TextArea rows={5} style={{color:"#FFFFFF",width:"98%",resize:"none",overflow:"hidden"}} bordered={false} value={record.ActualStopOver1}/>
            <div style={{color:"#FFFFFF",fontWeight:"bold"}}>　</div>
            <div style={{color:"#FFFFFF",fontWeight:"bold"}}><u>Stop Over2:</u></div>
            <TextArea rows={5} style={{color:"#FFFFFF",width:"98%",resize:"none",overflow:"hidden"}} bordered={false} value={record.ActualStopOver2}/>
            <div style={{color:"#FFFFFF",fontWeight:"bold"}}>　</div>
            <div style={{color:"#FFFFFF",fontWeight:"bold"}}><u>Stop Over3:</u></div>
            <TextArea rows={5} style={{color:"#FFFFFF",width:"98%",resize:"none",overflow:"hidden"}} bordered={false} value={record.ActualStopOver3}/>
          </>
      } arrow={true}>
        <TextArea rows={3} style={{width:"98%",resize:"none",overflow:"hidden"}} bordered={false} value={record.ActualStopOver1}/>
      </Tooltip>))
      ,
    },
    {
      title: '　Customer Request',
      key: 'HireRequest',
      dataIndex: 'HireRequest',
      render: (_:any,record:DataType) =>
      orderSunmmaryMenu(record,(
      <Tooltip  overlayStyle={{minWidth:300}} placement="left" title={
          <div>{record.HireRequest}</div>
      }><TextArea rows={3} style={{width:"98%",resize:"none",overflow:"hidden"}} bordered={false} value={record.HireRequest}/></Tooltip>)),
      sorter: (a, b) => a.HireRequest.localeCompare(b.HireRequest),
      width:"11%"
    },
    {
      title: 'Action',
      key: 'HireRequest',
      dataIndex: 'HireRequest',
      render: (_:any,record:DataType) =>
      orderSunmmaryMenu2(record,(<EllipsisOutlined style={{fontSize: '200%',width:"100%",height:"100%"}}/>)),
      sorter: (a, b) => a.HireRequest.localeCompare(b.HireRequest),
    },
    /*{
      title: '　Dispatch',
      key: 'Status',
      dataIndex: 'Status',
      render: (_:any,record:DataType) => <Tooltip  overlayStyle={{minWidth:300}} placement="left" title={
          <div>
            <Button
              hidden={record.Status === "Open"?false:((record.Status === "Dispatched"||record.Status === "Acknowledge"||record.Status === "Waiting")?false:true)}
              onClick={()=>{
                if(record.Status === "Open"){
                  
                }else if(record.Status === "Dispatched"){
                  
                }else if(record.Status === "Acknowledge"){
                  
                }else if(record.Status === "Waiting"){
                  
                }
              }}
            >
              {record.Status === "Open"?"Dispatched":"Re-Dispatched"}
            </Button>
          </div>
      }><TextArea rows={3} style={{width:"98%",resize:"none",overflow:"hidden"}} bordered={false} value={record.HireRequest}/></Tooltip>,
      sorter: (a, b) => a.HireRequest.localeCompare(b.HireRequest),
      width:"0%"
    },*/
  ];
  const ThemeContext = createContext({})
  const value = useContext(ThemeContext)
  function Panel({ title, children }) {
    const [TabIndex,SetTabIndex] = useState("0");
    return (
      <ThemeContext.Provider value="dark">
      <section>
        <h1>{title}</h1>
        {children}
      </section>
      </ThemeContext.Provider>
    )
  }
const columns3: ColumnsType<DataType> = [
  {
    title: '　Order No.\n　E-form Order No.',
    dataIndex: 'OrderNo',
    key: 'OrderNo',
    sorter: (a, b) =>  a.OrderNo.localeCompare(b.OrderNo),
    render:(_:any,record:DataType) =><Panel title={"Hi"}><Button>Hi</Button></Panel>,
    // render: (_:any,record:DataType) => 
    // orderSunmmaryMenu2(record,(
    // <div style={{height:tableRowHeight,width:"100%"}}>
    //   <Row><Text style={{width:"98%",fontSize:14,resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{" "+record.OrderNo}</Text></Row>
    //   <Row><Text style={{color:"#FF0000",fontSize:13,width:"98%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{" "+record.EformOrderNo}</Text></Row>
    //   {!(record.Status?.includes("fail") || record.Status?.includes("Upload") || record.Status === "Dropoff" || record.Status === "Noshow" || record.Status === "Cancel")?(
    //   <Row style={{marginTop:5,width:"100%"}}>
    //   <Col span={12}>
    //     {(record.SMSStatusPassenger.toString().replace(/103/g,"") !== "" || record.PassengerTelNo.toString() !== "")?
    //     (<Button type="primary"size="small"style={{
    //       width:"96%",
    //       background:(record.SMSStatusPassenger.toString() === "100")?("#00B400"):
    //       (
    //         (record.SMSStatusPassenger.toString() === "Resend" )?"#777777":
    //         (record.SMSStatusPassenger.toString() === ""?"#1677ff":"#FF0000")
    //       )
    //     }}
    //     onClick={
    //       ()=>{
    //         if(record.SMSStatusPassenger.toString().replace(/103/g,"").replace(/100/g,"").replace(/Resend/g,"") !== "" || true){
    //         confirmDialog2("Resend SMS to Passenger?","","Yes","Cancel",
    //           ()=>{
    //             ResendOrderSMS(record.OrderNo,"Passenger");
    //           },
    //           ()=>{
                
    //           });}
    //         }
    //     }
    //     >Pax SMS</Button>):(<Text style={{width:"96%"}}></Text>)}
    //   </Col>
    //   <Col span={12}>
    //     {(record.SMSStatusContact.toString().replace(/103/g,"") !== "" || record.ContactTelNo2.toString() !== "")?
    //     (<Button type="primary"size="small"style={{
    //       width:"96%",
    //       background:(record.SMSStatusContact.toString() === "100")?("#00B400"):
    //       (
    //         (record.SMSStatusContact.toString() === "Resend" )?"#777777":
    //         (record.SMSStatusContact.toString() === ""?"#1677ff":"#FF0000")
    //       )
    //     }}
    //     onClick={
    //       ()=>{
    //         if(record.SMSStatusContact.toString().replace(/103/g,"").replace(/100/g,"").replace(/Resend/g,"") !== "" || true){
    //         confirmDialog2("Resend SMS to Contact?","","Yes","Cancel",
    //           ()=>{
    //             ResendOrderSMS(record.OrderNo,"Contact");
    //           },
    //           ()=>{
                
    //           });}
    //         }
    //     }
    //     >Con SMS</Button>):(<Text style={{width:"96%"}}></Text>)}
    //   </Col>
    //   </Row>):(<div></div>)}
    // </div>)),
    width:"9%"
  },
  {
    title: '　Pickup',
    dataIndex: 'ActualOrderPickUpDatetime',
    key: 'ActualOrderPickUpDatetime',
    sorter: (a, b) => a.ActualOrderPickUpDatetime.localeCompare(b.ActualOrderPickUpDatetime),
    width:"5%"
  },
  {
    title: '　Status',
    key: 'Status',
    dataIndex: 'Status',
    sorter: (a, b) => a.Status.localeCompare(b.Status),
    filterSearch: false,
    width:"7%"
  },
  {
    title: '　Flight Info',
    key: 'ActualFlightNo',
    dataIndex: 'ActualFlightNo',
    sorter: (a, b) => a.FlightNo.localeCompare(b.FlightNo),
    width:"7%"
  },
  {
    title: '　Journey\n　Limo Class',
    key: 'Journey',
    dataIndex: 'Journey',
    sorter: (a, b) => a.Journey.localeCompare(b.Journey),
    width:"8%"
  },
  {
    title: '　Assigned Car\n　Assigned Driver',
    key: 'AssignVehicleID',
    dataIndex: 'AssignVehicleID',
    sorter: (a, b) => a.AssignVehicleID.localeCompare(b.AssignVehicleID),
    width:"8%"
  },
  {
    title: '　Passenger\n　Mobile No',
    key: 'PassengerName',
    dataIndex: 'PassengerName',
    sorter: (a, b) => a.PassengerName.localeCompare(b.PassengerName),
    width:"8%"
  },
  {
    title: '　Pick-up Location',
    key: 'ActualPickup',
    dataIndex: 'ActualPickup',
    sorter: (a, b) => a.ActualPickup.localeCompare(b.ActualPickup),
  },
  {
    title: '　Drop-off Location',
    key: 'ActualDestination',
    dataIndex: 'ActualDestination',
    sorter: (a, b) => a.ActualDestination.localeCompare(b.ActualDestination),
    width:"13%"
  },
  {
    title: '　Stop Over1',
    key: 'ActualStopOver1',
    dataIndex: 'ActualStopOver1',
    sorter: (a, b) => a.ActualStopOver1.localeCompare(b.ActualStopOver1),
  },
  {
    title: '　Customer Request',
    key: 'HireRequest',
    dataIndex: 'HireRequest',
    sorter: (a, b) => a.HireRequest.localeCompare(b.HireRequest),
    width:"11%"
  },
  {
    title: 'Action',
    key: 'HireRequest',
    dataIndex: 'HireRequest',
    sorter: (a, b) => a.HireRequest.localeCompare(b.HireRequest),
  },
];
const columns4: ColumnsType<DataType> = [
  {
    title: '　Order No.\n　E-form Order No.',
    dataIndex: 'OrderNo',
    key: 'OrderNo',
    sorter: (a, b) =>  a.OrderNo.localeCompare(b.OrderNo),
    render: (_:any,record:DataType) => 
    orderSunmmaryMenu(record,(
    <div style={{height:tableRowHeight,width:"100%"}}>
      <Row><Text style={{width:"98%",fontSize:14,resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{" "+record.OrderNo}</Text></Row>
      <Row><Text style={{color:"#FF0000",fontSize:13,width:"98%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{" "+record.EformOrderNo}</Text></Row>
      {!(record.Status?.includes("fail") || record.Status?.includes("Upload") || record.Status === "Dropoff" || record.Status === "Noshow" || record.Status === "Cancel")?(
      <Row style={{marginTop:5,width:"100%"}}>
      <Col span={12}>
        {(record.SMSStatusPassenger.toString().replace(/103/g,"") !== "" || record.PassengerTelNo.toString() !== "")?
        (<Button type="primary"size="small"style={{
          width:"96%",
          background:(record.SMSStatusPassenger.toString() === "100")?("#00B400"):
          (
            (record.SMSStatusPassenger.toString() === "Resend" )?"#777777":
            (record.SMSStatusPassenger.toString() === ""?"#1677ff":"#FF0000")
          )
        }}
        onClick={
          ()=>{
            if(record.SMSStatusPassenger.toString().replace(/103/g,"").replace(/100/g,"").replace(/Resend/g,"") !== "" || true){
            confirmDialog2("Resend SMS to Passenger?","","Yes","Cancel",
              ()=>{
                ResendOrderSMS(record.OrderNo,"Passenger");
              },
              ()=>{
                
              });}
            }
        }
        >Pax SMS</Button>):(<Text style={{width:"96%"}}></Text>)}
      </Col>
      <Col span={12}>
        {(record.SMSStatusContact.toString().replace(/103/g,"") !== "" || record.ContactTelNo2.toString() !== "")?
        (<Button type="primary"size="small"style={{
          width:"96%",
          background:(record.SMSStatusContact.toString() === "100")?("#00B400"):
          (
            (record.SMSStatusContact.toString() === "Resend" )?"#777777":
            (record.SMSStatusContact.toString() === ""?"#1677ff":"#FF0000")
          )
        }}
        onClick={
          ()=>{
            if(record.SMSStatusContact.toString().replace(/103/g,"").replace(/100/g,"").replace(/Resend/g,"") !== "" || true){
            confirmDialog2("Resend SMS to Contact?","","Yes","Cancel",
              ()=>{
                ResendOrderSMS(record.OrderNo,"Contact");
              },
              ()=>{
                
              });}
            }
        }
        >Con SMS</Button>):(<Text style={{width:"96%"}}></Text>)}
      </Col>
      </Row>):(<div></div>)}
    </div>)),
    width:"9%"
  },
  {
    title: '　Pickup',
    dataIndex: 'ActualOrderPickUpDatetime',
    key: 'ActualOrderPickUpDatetime',
    render: (_:any,record:DataType) => 
      orderSunmmaryMenu(record,(
      //<TextArea rows={3} style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}} bordered={false} value={((record.ActualOrderPickUpDatetime as string).length >= 16?record.ActualOrderPickUpDatetime.substring(10,16):"")+""}/>
      <div>
        <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{((record.ActualOrderPickUpDatetime as string).length >= 16?record.ActualOrderPickUpDatetime.substring(10,16):"")}</div>
    </div>
      )),
    sorter: (a, b) => a.ActualOrderPickUpDatetime.localeCompare(b.ActualOrderPickUpDatetime),
    width:"5%"
  },
  {
    title: '　Status',
    key: 'Status',
    dataIndex: 'Status',
    sorter: (a, b) => a.Status.localeCompare(b.Status),
    render: (_:any,record:DataType) => 
    orderSunmmaryMenu(record,(
    <div>
    {/* {<TextArea rows={3} style={{paddingLeft:20,width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap",color:record.Status!.toString()==='Cancel'?"#FF0000":"#000000"}} bordered={false} value={orderStatusReferenceIndexMap[record.Status] + "\n" +
      `${record.Status!.toString() === 'Waiting' ?record.DriverStartWaitDatetime!.substring(11,16):""}` +
      `${record.Status!.toString() === 'Pickup' ?record.DriverPickUpDatetime!.substring(11,16):""}` +
      `${record.Status!.toString().includes('Dropoff') ?record.DriverDropOffDatetime!.substring(11,16):""}`
    }/>} */}
    <div style={{paddingLeft:10,width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap",color:record.Status!.toString()==='Cancel'?"#FF0000":"#000000"}}>{orderStatusReferenceIndexMap[record.Status]}</div>
    <div style={{paddingLeft:10,width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap",color:record.Status!.toString()==='Cancel'?"#FF0000":"#000000"}}>{
      `${record.Status!.toString() === 'Waiting' ?record.DriverStartWaitDatetime!.substring(11,16):""}` +
      `${record.Status!.toString() === 'Pickup' ?record.DriverPickUpDatetime!.substring(11,16):""}` +
      `${record.Status!.toString().includes('Dropoff') ?record.DriverDropOffDatetime!.substring(11,16):""}`
    }</div>
    {/* {(record.Status!.toString() === 'Dropoff' ||record.Status!.toString() === 'Cancel' ||record.Status!.toString() === 'Noshow')?(<Checkbox style={{position:"absolute",top:5,left:2}} onChange={(e)=>{UploadOrderCheckList[record.OrderNo]=e.target.checked;SetUploadOrderCheckList(UploadOrderCheckList);ConsoleLog(record.OrderNo+":"+e.target.checked);ConsoleLog(UploadOrderCheckList[record.OrderNo])}}></Checkbox>):(null)} */}
    </div>
    )),
    filterSearch: false,
    width:"7%"
  },
  {
    title: '　Flight Info',
    key: 'ActualFlightNo',
    dataIndex: 'ActualFlightNo',
    render: (_:any,record:DataType) => 
    orderSunmmaryMenu(record,(
    // <TextArea rows={3} style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}} bordered={false} value={(record.ActualFlightNo === ""?"---":record.ActualFlightNo)+"\n"+
    // ((record.ActualFlightSchedule ==="0001-01-01 00:00:00"?"":(record.ActualFlightSchedule.length>=16?record.ActualFlightSchedule.substring(11,16):record.ActualFlightSchedule))) + "\n" +
    // (record.FlightStatus === "---"?"":record.FlightStatus)}/>
    <div>
      <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{(record.ActualFlightNo === ""?"---":record.ActualFlightNo)}</div>
      <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{((record.ActualFlightSchedule ==="0001-01-01 00:00:00"?"　":(record.ActualFlightSchedule.length>=16?record.ActualFlightSchedule.substring(11,16):record.ActualFlightSchedule)))}</div>
      <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{(record.FlightStatus === "---"?"":record.FlightStatus)}</div>
    </div>
    )),
    sorter: (a, b) => a.FlightNo.localeCompare(b.FlightNo),
    width:"5%"
  },
  {
    title: '　Journey\n　Limo Class',
    key: 'Journey',
    dataIndex: 'Journey',
    sorter: (a, b) => a.Journey.localeCompare(b.Journey),
    render: (_:any,record:DataType) => 
    orderSunmmaryMenu(record,(
    //<TextArea rows={3} style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}} bordered={false} value={record.Journey+"\n"+record.CarType}/>
    <div>
      <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.Journey}</div>
      <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.CarType}</div>
    </div>
    )),
    width:"8%"
  },
  {
    title: '　Provider',
    key: 'Provider',
    dataIndex: 'Provider',
    width:"5%",
    render: (_:any,record:DataType) =>
    orderSunmmaryMenu(record,(
      <div>
        <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.ServiceProvider}</div>
      </div>
    )),
    sorter: (a, b) => a.ServiceProvider.localeCompare(b.ServiceProvider),
  },
  {
    title: '　Vendor',
    key: 'Provider',
    dataIndex: 'Provider',
    width:"4%",
    render: (_:any,record:DataType) =>
    orderSunmmaryMenu(record,(
      <div>
        <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.VendorName}</div>
      </div>
    )),
    sorter: (a, b) => a.VendorName.localeCompare(b.VendorName),
  },
  {
    title: '　Assigned Car\n　Assigned Driver',
    key: 'AssignVehicleID',
    dataIndex: 'AssignVehicleID',
    sorter: (a, b) => a.AssignVehicleID.localeCompare(b.AssignVehicleID),
    render: (_:any,record:DataType) => 
    orderSunmmaryMenu(record,(
    //<TextArea rows={3} style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}} bordered={false} value={record.AssignVehicleID+"\n"+record.DriverName+"\n"+record.DriverMobile}/>
    <div>
      <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.AssignVehicleID}</div>
      <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.DriverName}</div>
      <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.DriverMobile}</div>
    </div>
    )),
    width:"9%"
  },
  {
    title: '　Passenger\n　Mobile No',
    key: 'PassengerName',
    dataIndex: 'PassengerName',
    sorter: (a, b) => a.PassengerName.localeCompare(b.PassengerName),
    render: (_:any,record:DataType) => 
    orderSunmmaryMenu(record,(
    //<TextArea rows={3} style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}} bordered={false} value={record.PassengerName+"\n"+record.PassengerTelNo}/>
    <div>
      <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.PassengerName}</div>
      <div style={{width:"100%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.PassengerTelNo}</div>
    </div>
    )),
    width:"9%"
  },
  {
    title: '　Pick-up Location',
    key: 'ActualPickup',
    dataIndex: 'ActualPickup',
    sorter: (a, b) => a.ActualPickup.localeCompare(b.ActualPickup),
    render: (_:any,record:DataType) => 
    orderSunmmaryMenu(record,(
    <TextArea rows={3} style={{width:"98%",resize:"none",overflow:"hidden",backgroundColor:"#FFFFFF00",border:"0px"}} bordered={false} value={record.ActualPickup}/>
    //<div style={{width:"98%",resize:"none",overflow:"hidden"}}>{record.ActualPickup}</div>
    )),
    width:"10%"
  },
  {
    title: '　Drop-off Location',
    key: 'ActualDestination',
    dataIndex: 'ActualDestination',
    sorter: (a, b) => a.ActualDestination.localeCompare(b.ActualDestination),
    render: (_:any,record:DataType) => 
    orderSunmmaryMenu(record,(
    <TextArea rows={3} style={{width:"98%",resize:"none",overflow:"hidden",backgroundColor:"#FFFFFF00",border:"0px"}} bordered={false} value={record.ActualDestination}/>
    //<div style={{width:"98%",resize:"none",overflow:"hidden",whiteSpace:"pre-wrap"}}>{record.ActualDestination}</div>
    )),
    width:"10%"
  },
  {
    title: '　Stop Over1',
    key: 'ActualStopOver1',
    dataIndex: 'ActualStopOver1',
    sorter: (a, b) => a.ActualStopOver1.localeCompare(b.ActualStopOver1),
    render: (_:any,record:DataType) => 
    orderSunmmaryMenu(record,(
    <Tooltip overlayStyle={{minWidth:450}} placement="left" title={
        <>
          <div style={{color:"#FFFFFF",fontWeight:"bold"}}><u>Stop Over1:</u></div>
          <TextArea rows={5} style={{color:"#FFFFFF",width:"98%",resize:"none",overflow:"hidden",backgroundColor:"#FFFFFF00",border:"0px"}} bordered={false} value={record.ActualStopOver1}/>
          <div style={{color:"#FFFFFF",fontWeight:"bold"}}>　</div>
          <div style={{color:"#FFFFFF",fontWeight:"bold"}}><u>Stop Over2:</u></div>
          <TextArea rows={5} style={{color:"#FFFFFF",width:"98%",resize:"none",overflow:"hidden",backgroundColor:"#FFFFFF00",border:"0px"}} bordered={false} value={record.ActualStopOver2}/>
          <div style={{color:"#FFFFFF",fontWeight:"bold"}}>　</div>
          <div style={{color:"#FFFFFF",fontWeight:"bold"}}><u>Stop Over3:</u></div>
          <TextArea rows={5} style={{color:"#FFFFFF",width:"98%",resize:"none",overflow:"hidden",backgroundColor:"#FFFFFF00",border:"0px"}} bordered={false} value={record.ActualStopOver3}/>
        </>
    } arrow={true}>
      <TextArea rows={3} style={{width:"98%",resize:"none",overflow:"hidden",backgroundColor:"#FFFFFF00",border:"0px"}} bordered={false} value={record.ActualStopOver1}/>
    </Tooltip>)),
    width:"8%"
  },
  {
    title: '　Customer Request',
    key: 'HireRequest',
    dataIndex: 'HireRequest',
    render: (_:any,record:DataType) =>
    orderSunmmaryMenu(record,(record.HireRequest!==""?
    <Tooltip  overlayStyle={{minWidth:300}} placement="left" title={
        <div>{record.HireRequest}</div>
    }><TextArea rows={3} style={{width:"98%",resize:"none",overflow:"hidden",backgroundColor:"#FFFFFF00",border:"0px"}} bordered={false} value={record.HireRequest}/></Tooltip>:
    null
    )),
    sorter: (a, b) => a.HireRequest.localeCompare(b.HireRequest),
    width:"7%"
  },
  {
    title: '　Action',
    key: 'HireRequest',
    dataIndex: 'HireRequest',
    width:"4%",
    render: (_:any,record:DataType) =>
        orderSunmmaryMenu(record,
          <div>
            <div>{orderSunmmaryMenu2(record,<EllipsisOutlined style={{fontSize: '200%',width:"100%",height:"100%"}}/>)}</div>
            <Button key="Audit" type="primary" style={{background:((""+record.MLSUpdateAlert) === "1")?"#E46C0A":"#777777"}} onClick={()=>{GetAuditTrailByOrderNo(record.OrderNo);UpdateOrderMLSUpdateAlert(record.OrderNo,"2")}}>
              {"Audit"}
            </Button>
          </div>),
    sorter: (a, b) => a.HireRequest.localeCompare(b.HireRequest),
  },
];
const GetDriver = () =>{
    var para0 = "";
    var para1 = "action=GetDriver";
    var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
    var para3 = para1 + para2;
    //ConsoleLog(para3)
    ConsoleLog("GetDriver")
    GetData(para3,
        data => {
        //ConsoleLog(data)
        if(data["result"] === "200"){
          var list = JSON.parse(data["itemObject"])
          var selectItemList:SelectOption[] = []
          var list2:any[] = []
          var selectItemList2:SelectOption[] = []
          for(var k = 0;k<list.length;k++){
            selectItemList.push({label: list[k]['Name'], value: list[k]['DriverID']})
            if(OrderDetailPageStatus["ServiceProvider"] === "DCH" && list[k]['ServiceProvider'] === "DCH"){
                selectItemList2.push({label: list[k]['Name'], value: list[k]['DriverID']})
                list2.push(list[k])
            }else if(OrderDetailPageStatus["ServiceProvider"] === "Consignment" && list[k]['ServiceProvider'] === "Consignment" && (list[k]['Vendor'] === OrderDetailPageStatus["VendorName"] || OrderDetailPageStatus["VendorName"] === "")){// || list[k]['Vendor'] === ""
                selectItemList2.push({label: list[k]['Name'], value: list[k]['DriverID']})
                list2.push(list[k])
            }
          }
          
          SetDriverDetailList(list)
          SetDriverFilterDetailList(list2)
          SetDriverFilterList(selectItemList2)
          GetVehicle()
        }else{
            if(!IsErrorDialog){
                SetIsErrorDialog(true)
                oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false)})
            }
        }
        SetLoading(false)
        },
        ()=>{
            if(!IsErrorDialog){
                SetIsErrorDialog(true)
                oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
            }
            SetLoading(false);
        }
    )
}

const GetVehicle = () =>{
    var para0 = "";
    var para1 = "action=GetVehicle";
    var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
    var para3 = para1 + para2;
    //ConsoleLog(para3)
    ConsoleLog("GetVehicle")
    GetData(para3,
        data => {
        //ConsoleLog(data)
        if(data["result"] === "200"){
            var list = JSON.parse(data["itemObject"])
            var selectItemList:SelectOption[] = []
            for(var k = 0;k<list.length;k++){
            selectItemList.push({label: list[k]['VehicleID'], value: list[k]['VehicleID']})
            }
            SetVehicleList(selectItemList)
            if(OrderDetailPageStatus["ServiceProvider"] === "DCH"){
                SetVehicleFilterList(selectItemList)
            }else{
                SetVehicleFilterList([])
            }
        }else{
            if(!IsErrorDialog){
                SetIsErrorDialog(true)
                oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false)})
            }
        }
        SetLoading(false)
        },
        ()=>{
            if(!IsErrorDialog){
                SetIsErrorDialog(true)
                oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
            }
            SetLoading(false);
        }
    )
}

const GetVendor = () =>{
  var para0 = "";
  var para1 = "action=GetVendor";
  var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
  var para3 = para1 + para2;
  //ConsoleLog(para3)
  //ConsoleLog("GetDriver")
  GetData(para3,
      data => {
      //ConsoleLog(data)
      if(data["result"] === "200"){
        var list = JSON.parse(data["itemObject"])
        var selectItemList:SelectOption[] = []
        for(var k = 0;k<list.length;k++){
          //ConsoleLog(list[k]['VendorName'])
          selectItemList.push({label: list[k]['VendorName'].toString(), value: list[k]['VendorName'].toString()})
        }
        SetServiceVenderList(selectItemList)
        SetServiceVenderListfiltered(selectItemList)
        //SetDriverList(selectItemList)
      }else{
          if(!IsErrorDialog){
              SetIsErrorDialog(true)
              oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false)})
          }
      }
      SetLoading(false)
      },
      ()=>{
          if(!IsErrorDialog){
              SetIsErrorDialog(true)
              oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
          }
          SetLoading(false);
      }
  )
}
/*
{
    "Acknowledge": "Acknowledge",
    "Cancel": "Cancelled",
    "CancelUploaded": "Cancelled Uploaded",
    "CancelUploadFail": "Cancelled Upload Fail",
    "Confirm": "Confirm",
    "Dispatched": "Dispatched",
    "Dropoff": "Completed",
    "DropoffUploaded": "Completed Uploaded",
    "DropoffUploadFail": "Completed Upload Fail",
    "Noshow": "No Show",
    "NoshowUploaded": "No Show Uploaded",
    "NoshowUploadFail": "No Show Upload Fail",
    "NotAvailable": "Not Available",
    "Open": "Open",
    "Pickup": "Pick-up",
    "TBC": "TBC",
    "Waiting": "Waiting"
}
*/
  const GetOrderStatusReference = (OrderType_String) =>{
    var para0 = "";
    var para1 = "action=GetOrderStatusReference";
    var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
    var para3 = para1 + para2;
    GetData(para3,
      data => {
        if(data["result"] === "200"){
          if(data["itemObject"] !== "{}"){
            var orderListFromApi = JSON.parse(data["itemObject"])
            var orderListIndex = {}
            for(var j=0;j<orderListFromApi.length;j++){
              orderListIndex[orderListFromApi[j]["StatusName"]] = orderListFromApi[j]["DisplayName"]
            }
            SetOrderStatusReferenceRawList(orderListFromApi)
            SetOrderStatusReferenceIndexMap(orderListIndex)
            GetOrder(localStorage.getItem('DCHSelectedOrderSummaryDate'),OrderType_String)
          }else{

          }
        }else{
          if(!IsErrorDialog){
            SetIsErrorDialog(true)
            oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false);window.location.replace(webUrl)})
          }
        }
        SetLoading(false)
      },
      ()=>{
        if(!IsErrorDialog){
          SetIsErrorDialog(true)
          oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false);window.location.replace(webUrl)})
        }
        SetLoading(false);
      }
    )
  }

  const ResendOrderSMS = (orderNo,type) =>{
    var para0 = JSON.stringify({OrderNo:orderNo,Type:type});
    var para1 = "action=ResendOrderSMS";
    var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
    var para3 = para1 + para2 + "&itemObject=" + para0;
    GetData(para3,
      data => {
        if(data["result"] === "200"){
          GetOrder(localStorage.getItem('DCHSelectedOrderSummaryDate'),OrderType);
        }else{
          if(!IsErrorDialog){
            SetIsErrorDialog(true)
            oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false);window.location.replace(webUrl)})
          }
        }
        SetLoading(false)
      },
      ()=>{
        if(!IsErrorDialog){
          SetIsErrorDialog(true)
          oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false);window.location.replace(webUrl)})
        }
        SetLoading(false);
      }
    )
  }

  const UpdateOrderReadyToUpload = (orderNo) =>{
    var para0 = JSON.stringify({OrderNo:orderNo});
    var para1 = "action=UpdateOrderReadyToUpload";
    var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
    var para3 = para1 + para2 + "&itemObject=" + para0;
    //ConsoleLog(para3)
    GetData(para3,
      data => {
        //ConsoleLog(data)
        if(data["result"] === "200"){
          SetUploadOrderCheckList([])
          GetOrder(localStorage.getItem('DCHSelectedOrderSummaryDate'),OrderType);
        }else{
          if(!IsErrorDialog){
            SetIsErrorDialog(true)
            oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false);window.location.replace(webUrl)})
          }
        }
        setSelectedRowKeys([])
        SetLoading(false)
      },
      ()=>{
        if(!IsErrorDialog){
          SetIsErrorDialog(true)
          oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false);window.location.replace(webUrl)})
        }
        SetLoading(false);
      }
    )
  }

  const UpdateOrderAssignDriver = (orderNo,assignvehicleid,assigndriverid,drivername,drivermobile,status) =>{
    var para0 = JSON.stringify({OrderNo:orderNo,AssignVehicleID:assignvehicleid,AssignDriverID:assigndriverid,DriverName:drivername,DriverMobile:drivermobile,Status:status});
    var para1 = "action=UpdateOrderAssignDriver";
    var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
    var para3 = para1 + para2 + "&itemObject=" + para0;
    GetData(para3,
      data => {
        if(data["result"] === "200"){
          GetOrder(localStorage.getItem('DCHSelectedOrderSummaryDate'),OrderType);
        }else{
          if(!IsErrorDialog){
            SetIsErrorDialog(true)
            oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false);window.location.replace(webUrl)})
          }
        }
        SetLoading(false)
      },
      ()=>{
        if(!IsErrorDialog){
          SetIsErrorDialog(true)
          oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false);window.location.replace(webUrl)})
        }
        SetLoading(false);
      }
    )
  }
  const UpdateOrder = (orderNo,assignvehicleid,assigndriverid,drivername,drivermobile,serviceprovider,vendorname,status) =>{
    var paraMap1 = { OrderNo:orderNo,
        AssignVehicleID:assignvehicleid,
        AssignDriverID:assigndriverid,
        DriverName:drivername,
        DriverMobile:drivermobile,
        ServiceProvider:serviceprovider,
        VendorName:vendorname,
        Status:status,
    }

    SetLoading(true);

    var para0 = JSON.stringify(paraMap1);
    var para1 = "action=UpdateOrder";
    var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
    var para3 = para1 + para2 + "&itemObject=" + para0.replace(/&#/g,"%26%20%23").replace(/&/g,"%26").replace(/#/g,"%23");
    ConsoleLog(para3)
    GetData(para3,
      data => {ConsoleLog(data)
        ConsoleLog(data["result"])
        if(data["result"] === "200"){
            GetOrder(localStorage.getItem('DCHSelectedOrderSummaryDate'),OrderType);
        }else if(data["result"] === "201"){
            if(!IsErrorDialog){
                SetIsErrorDialog(true)
                oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false)})
            }
        }else{
            if(!IsErrorDialog){
                SetIsErrorDialog(true)
                oneButtonConfirmDialog("Error","Please Try Again",()=>{SetIsErrorDialog(false)})
            }
        }
        SetLoading(false)
      },
        ()=>{
            if(!IsErrorDialog){
                SetIsErrorDialog(true)
                oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
            }
            SetLoading(false);
        }
    )
  }
  
  const GetOrder = (dateString,type) =>{
    var para0 = JSON.stringify({DTStart:dateString + ' 00:00:00',DTEnd:dateString +" 23:59:59",StatusType:type});
    var para1 = "action=GetOrderByDT";
    var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
    var para3 = para1 + para2 + "&itemObject=" + para0;
    GetData(para3,
      data => {
        if(data["result"] === "200"){
          if(data["itemObject"] !== "{}"){
            var orderListFromApi = JSON.parse(data["itemObject"])
            SetOrderList(orderListFromApi)
            searchFunction(orderListFromApi)
          }else{
            SetOrderList([])
            SetOrderSearchedList([])
          }
        }else{
          if(!IsErrorDialog){
            SetIsErrorDialog(true)
            oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false);window.location.replace(webUrl)})
          }
        }
        SetLoading(false)
      },
      ()=>{
        if(!IsErrorDialog){
          SetIsErrorDialog(true)
          oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false);window.location.replace(webUrl)})
        }
        SetLoading(false);
      }
    )
  }

  const GetAuditTrailByOrderNo = (OrderNo) =>{
    //SetLoading(true)
    //ConsoleLog("API:"+OrderNo)
    var para0 = JSON.stringify({'OrderNo':OrderNo,"System":"MLS"});
    var para1 = "action=GetAuditTrailByOrderNo";
    var para2 = `&token=${localStorage.getItem("DCHtoken")}`;
    var para3 = para1 + para2 + "&itemObject=" + para0;
    ConsoleLog(para3)
    GetData(para3,
        data => {
        ConsoleLog(data)
        if(data["result"] === "200"){
            if(data["itemObject"] !== "{}"){
                var list = JSON.parse(data["itemObject"].replace("	","\t"))
                var listsize = list.length
                var AuditShowing:any = []
                AuditShowing.push(<div style={{background:"#DDDDDD",width:"100%",height:1}} />)
                if(listsize > 0){

                    for(var i = 0;i<listsize;i++){
                        ConsoleLog(list[i])
                        //AuditShowing.push(<Row><Text>{"AuditDatetime : "+list[i]["AuditDatetime"]}</Text></Row>)
                        var AuditDatetime = list[i]["AuditDatetime"]
                        var System = list[i]["System"]
                        //var Category = list[i]["Category"] .replace(/\n/g,"\\n").replace(/\\/g,"").replace(/\'/g,"").replace(/\"/g,"").replace(/\{/g,"").replace(/\}/g,"").replace(/\[/g,"").replace(/\]/g,"")
                        var UpdatedBy = list[i]["UpdatedBy"]
                        var list2 = JSON.parse(list[i]["Data"].replace(/\n/g,"\\n"))
                        var list2size = list2.length
                        var editData:any = []
                        for(var j = 0;j<list2size;j++){
                            ConsoleLog(list2[j])
                            ConsoleLog(list2[0]["Type"])
                            ConsoleLog(list2[0]["Old"])
                            ConsoleLog(list2[0]["New"])
                            editData.push(<Row>
                                    <Col span={8}><Text>{list2[j]["Type"]}</Text></Col>
                                    <Col span={8}><Text>{list2[j]["Old"]}</Text></Col>
                                    <Col span={8}><Text>{list2[j]["New"]}</Text></Col>
                                </Row>)
                        }
                        AuditShowing.push(<Row style={{width:900}}>
                            <Col span={4}><Text>{AuditDatetime}</Text></Col>
                            <Col span={4}><Text>{System}</Text></Col>
                            <Col span={4}><Text>{UpdatedBy}</Text></Col>
                            <Col span={12}>{editData}</Col>
                            </Row>)
                        AuditShowing.push(<div style={{background:"#DDDDDD",width:900,height:1}} />)
                    }
                    setAuditTrailContent(AuditShowing)
                    setIsAuditTrail(true)
                }else{
                    AuditShowing.push(<Row><Text>{"No Record"}</Text></Row>)
                    setAuditTrailContent(AuditShowing)
                    setIsAuditTrail(true)
                }
            }else{
              oneButtonConfirmDialog("No Audit Found","",()=>{})
            }
        }else{
            if(!IsErrorDialog){
                SetIsErrorDialog(true)
                oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false)})
            }
        }
        SetLoading(false)
        },
        ()=>{
            if(!IsErrorDialog){
                SetIsErrorDialog(true)
                oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
            }
            SetLoading(false);
        }
    )
}
const UpdateOrderMLSUpdateAlert = (orderNo,mLSUpdateAlert) =>{
  var para0 = JSON.stringify({OrderNo:orderNo,MLSUpdateAlert:mLSUpdateAlert});
  var para1 = "action=UpdateOrderMLSUpdateAlert";
  var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
  var para3 = para1 + para2 + "&itemObject=" + para0;
  GetData(para3,
    data => {
      if(data["result"] === "200"){
        GetOrder(localStorage.getItem('DCHSelectedOrderSummaryDate'),OrderType);
      }else{
        if(!IsErrorDialog){
          SetIsErrorDialog(true)
          oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false);window.location.replace(webUrl)})
        }
      }
      SetLoading(false)
    },
    ()=>{
      if(!IsErrorDialog){
        SetIsErrorDialog(true)
        oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false);window.location.replace(webUrl)})
      }
      SetLoading(false);
    }
  )
}
  useEffect(() => {
    resize()
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  });
  useEffect(
    () => {
      const timer = setTimeout(() => {
        if(true){
          var orderType = ""
          if(localStorage.getItem(`DCHOrderType`)?.substring(0,1) === "1"){
            SetTabIndex("1");
            SetOrderType("Outstanding");
            orderType = "Outstanding";
          }else if(localStorage.getItem(`DCHOrderType`)?.substring(1,2) === "1"){
                  SetTabIndex("2");
                  SetOrderType("In-Progress");
                  orderType = "In-Progress";
          }else if(localStorage.getItem(`DCHOrderType`)?.substring(2,3) === "1"){
                  SetTabIndex("3");
                  SetOrderType("Complete");
                  orderType = "Complete";
          }else if(localStorage.getItem(`DCHOrderType`)?.substring(3,4) === "1"){
                  SetTabIndex("4");
                  SetOrderType("Noshow");
                  orderType = "Noshow";
          }else if(localStorage.getItem(`DCHOrderType`)?.substring(4,5) === "1"){
                  SetTabIndex("5");
                  SetOrderType("Cancel");
                  orderType = "Cancel";
          }else if(localStorage.getItem(`DCHOrderType`)?.substring(5,6) === "1"){
                  SetTabIndex("6");
                  SetOrderType("Uploaded");
                  orderType = "Uploaded";
          }else if(localStorage.getItem(`DCHOrderType`)?.substring(6,7) === "1"){
                  SetTabIndex("7");
                  SetOrderType("Departure");
                  orderType = "Departure";
          }else if(localStorage.getItem(`DCHOrderType`)?.substring(7,8) === "1"){
                  SetTabIndex("8");
                  SetOrderType("Arrival");
                  orderType = "Arrival";
          }
          GetDriver()
          GetVehicle()
          GetVendor()
          GetOrderStatusReference(orderType)
        }
      }, 500);
      return () => clearTimeout(timer)
    }
  ,[]);
  useEffect(
    () => {
      const interval = setInterval(() => {
        GetOrder(localStorage.getItem('DCHSelectedOrderSummaryDate'),OrderType)
        //ConsoleLog(selectedRowKeys)
      }, 5000);
      //const intervalId = interval[Symbol.toPrimitive]();
      return () => clearInterval(interval);
    }
  )

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (_selectedRowKeys, _selectedRows) => {
      //console.log(`selectedRowKeys: ${_selectedRowKeys}`, 'selectedRows: ', _selectedRows);
      setSelectedRowKeys(_selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: (!(record.Status === 'Dropoff' || record.Status === 'Cancel' || record.Status === 'Noshow') || ((""+record.ReadyToUpload) === "1")),
      // && (record.ReadyToUpload === "1")
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  ConsoleLog("render")
  const tableList = useMemo(()=>{
    return(
  <Table
    virtual 
    rowSelection={{
      columnWidth:30,
      type: 'checkbox',
      ...rowSelection,
    }}
    style={{minWidth:1440,height:(maxModalSize - headerHeight - tabHeight - (paddingValue*2) - 58 - 10 - 55),minHeight:650,padding:'0vh',whiteSpace: 'pre'}} 
    scroll={{ x: 1440, y: (maxModalSize>900?(maxModalSize - headerHeight - tabHeight - (paddingValue*2) - 58 - 60 - 30 - 55):575) }} columns={columns4} 
    dataSource={OrderSearchedList} pagination={{ position:['topLeft'],defaultPageSize: 50, showSizeChanger: false, pageSizeOptions: ['50', '75', '100']}}
  onRow={(r) => ({
    onDoubleClick: (e) => {
      //ConsoleLog(r.ReadyToUpload)
      ConsoleLog((""+r.ReadyToUpload) === "1")
      ConsoleLog((""+r.ReadyToUpload) === "0")
      localStorage.setItem('DCHOrderNo',r.OrderNo)
      window.open (`${webUrl}orderdetailp`,
      "orderdetail","menubar=1,resizable=1,width=1400,height=750");
    },
  })}
  size='small'
  />
    )
  },[OrderSearchedList,selectedRowKeys])
  const addButton = <div style={{}}> 
  <Button type="primary" 
    style={{width:100,position: 'absolute',right:10,bottom:10}}
    onClick={()=>{
      localStorage.setItem('DCHOrderNo',"")
      localStorage.setItem('DCHBookingDatetime',TolocalDateString(new Date().toISOString()).substring(0,16))
      localStorage.setItem('DCHCarType',"Benz S")
      localStorage.setItem('DCHJourney',"Point-Point Transfer")
      localStorage.setItem('DCHTitle',"MR")
      localStorage.setItem('DCHCustomerType',"Corporate")
      localStorage.setItem('DCHCustomerName',"")
      localStorage.setItem('DCHAddress',"")
      localStorage.setItem('DCHContactName1',"")
      localStorage.setItem('DCHContactName2',"")
      localStorage.setItem('DCHContactTelNo1',"0")
      localStorage.setItem('DCHContactTelNo2',"0")
      localStorage.setItem('DCHPassengerName',"")
      localStorage.setItem('DCHPassengerTelNo',"0")
      localStorage.setItem('DCHEstimatedOrderDuration',"0")
      localStorage.setItem('DCHActualPickup',"")
      localStorage.setItem('DCHActualDestination',"")
      localStorage.setItem('DCHActualStopOver1',"")
      localStorage.setItem('DCHActualStopOver2',"")
      localStorage.setItem('DCHActualStopOver3',"")
      localStorage.setItem('DCHPickup',"")
      localStorage.setItem('DCHDestination',"")
      localStorage.setItem('DCHStopOver1',"")
      localStorage.setItem('DCHStopOver2',"")
      localStorage.setItem('DCHStopOver3',"")

      localStorage.setItem('DCHActualFlightNo',"")
      localStorage.setItem('DCHFlightNo',"")
      localStorage.setItem('DCHFlightStatus',"")
      localStorage.setItem('DCHFlightSchedule',"")

      localStorage.setItem('DCHNoOfPassenger',"1")
      localStorage.setItem('DCHNoOfLuggage',"0")			
      localStorage.setItem('DCHHireRequest',"")

      localStorage.setItem('DCHDriverDropOffDatetime',"")
      localStorage.setItem('DCHDriverDropOffDatetime2',"")
      localStorage.setItem('DCHDriverPickUpDatetime',"")
      localStorage.setItem('DCHDriverPickUpDatetime2',"")
      localStorage.setItem('DCHDriverStartWaitDatetime',"")
      localStorage.setItem('DCHDriverStartWaitDatetime2',"")
      localStorage.setItem('DCHDriverWaitDuration',"")
      localStorage.setItem('DCHDriverWaitDuration2',"")

      localStorage.setItem('DCHOrderCharge',"0")
      localStorage.setItem('DCHAdditionalCharge',"0")
      localStorage.setItem('DCHParkingCharge',"0")
      localStorage.setItem('DCHTunnelCharge',"0")
      localStorage.setItem('DCHParkingCharge2',"0")
      localStorage.setItem('DCHTunnelCharge2',"0")

      localStorage.setItem('DCHAssignVehicleID',"")
      localStorage.setItem('DCHAssignDriverID',"")
      localStorage.setItem('DCHAssignVehicleID2',"")
      localStorage.setItem('DCHAssignDriverID2',"")
      localStorage.setItem('DCHDriverName',"")
      localStorage.setItem('DCHDriverMobile',"")			
      localStorage.setItem('DCHStatus',"Opened")			
      localStorage.setItem('DCHRemarks',"")
      localStorage.setItem('DCHVendorName',"")
      localStorage.setItem('DCHVendorDriver',"")

      localStorage.setItem('DCHHavePhoto',"")
      localStorage.setItem('DCHHaveMeetNGreet',"")
      localStorage.setItem('DCHServiceProvider',"")
      localStorage.setItem('DCHFirstReminder',"")
      localStorage.setItem('DCHSpecialInstructions',"")

      localStorage.setItem('DCHActualOrderPickUpDatetime',TolocalDateString(new Date().toISOString()))
      localStorage.setItem('DCHActualOrderPickUpDate',TolocalDateString(new Date().toISOString()).substring(0,10))
      localStorage.setItem('DCHActualOrderPickUpTime',TolocalDateString(new Date().toISOString()).substring(11,16))
      localStorage.setItem('DCHOrderPickUpDatetime',TolocalDateString(new Date().toISOString()))
      localStorage.setItem('DCHOrderPickUpDate',TolocalDateString(new Date().toISOString()).substring(0,10))
      localStorage.setItem('DCHOrderPickUpTime',TolocalDateString(new Date().toISOString()).substring(11,16))

      localStorage.setItem('DCHTunnelChargeList',"")
      localStorage.setItem('DCHTunnelString',"")

      localStorage.setItem('DCHCreatedBy',"")
      localStorage.setItem('DCHCreatedDatetime',"")			
      localStorage.setItem('DCHUpdatedBy',"")
      localStorage.setItem('DCHUpdatedDatetime',"")
      localStorage.setItem('DCHReadyToUpload',"0")
      localStorage.setItem('DCHOrderLock',"0")
      window.open (`${webUrl}orderdetail`,
    "orderdetail","menubar=1,resizable=1,width=1400,height=750");
    }}>
    Add
  </Button>
</div>

const dialogAudit = <Modal title={AuditTrailTitle} open={IsAuditTrail} onOk={()=>{}} onCancel={()=>{setIsAuditTrail(false)}}
width={1000}
bodyStyle={{height:500}}
footer={[

<Button key="back" onClick={()=>{setIsAuditTrail(false)}}>
    Return
</Button>,
]}

>
    <Row style={{width:900,background:"#EEEEEE"}}>
        <Col span={4}><Text style={{fontWeight:"bold"}}>{"DateTime"}</Text></Col>
        <Col span={4}><Text style={{fontWeight:"bold"}}>{"System"}</Text></Col>
        <Col span={4}><Text style={{fontWeight:"bold"}}>{"UpdatedBy"}</Text></Col>
        <Col span={4} style={{fontWeight:"bold"}}>{"Change"}</Col>
        <Col span={4} style={{fontWeight:"bold"}}>{"Old"}</Col>
        <Col span={4} style={{fontWeight:"bold"}}>{"New"}</Col>
    </Row>
    <Row style={{marginTop:5,height:480,overflow:"scroll"}}>
        <Col span={24}>
            {AuditTrailContent}
        </Col>
    </Row>
</Modal>

const [searchValue1, setSearchValue1] = useState('');
const [searchValue2, setSearchValue2] = useState('');
const [searchValue3, setSearchValue3] = useState('');
const [searchValue4, setSearchValue4] = useState('');
const [searchValue5, setSearchValue5] = useState('');
const [searchValue6, setSearchValue6] = useState('');

const searchValue1Ref = useRef<InputRef>(null);
const searchValue2Ref = useRef<InputRef>(null);
const searchValue3Ref = useRef<InputRef>(null);
const searchValue4Ref = useRef<InputRef>(null);
const searchValue5Ref = useRef<InputRef>(null);
const searchValue6Ref = useRef<InputRef>(null);

const searchFunction = (OrderList)=>{
  // ConsoleLog(
  //   searchValue1Ref.current!.input?.value + ":"+
  //   searchValue2Ref.current!.input?.value + ":"+
  //   searchValue3Ref.current!.input?.value + ":"+
  //   searchValue4Ref.current!.input?.value + ":"+
  //   searchValue5Ref.current!.input?.value + ":"
  //   )
  const listSize = OrderList.length
  var tempObject:DataType[] = []
  if(
    //searchValue1Ref.current!.input?.value.toString() !== "" ||
    //searchValue2Ref.current!.input?.value.toString() !== "" ||
    searchValue3.toString() !== "" ||
    searchValue4.toString() !== "" ||
    searchValue5Ref.current!.input?.value.toString() !== "" ||
    searchValue6Ref.current!.input?.value.toString() !== ""
  ){
    var countNum = 0
    for(var i = 0; i<listSize; i++){
      // if(searchValue1Ref.current!.input?.value.toString() !== "" && OrderList[i]["FlightStatus"].toUpperCase().includes(searchValue1.toUpperCase())){
      //   OrderList[i]["key"] = countNum
      //   countNum++
      //   tempObject.push(OrderList[i])
      // }
      // if(searchValue2Ref.current!.input?.value.toString() !== "" && OrderList[i]["FlightStatus"].toUpperCase().includes(searchValue2.toUpperCase())){
      //   OrderList[i]["key"] = countNum
      //   countNum++
      //   tempObject.push(OrderList[i])
      // } 
      if(searchValue3.toString() !== "" && OrderList[i]["ServiceProvider"].toUpperCase().includes(searchValue3.toString().toUpperCase())){
        OrderList[i]["key"] = countNum
        countNum++
        tempObject.push(OrderList[i])
      }
      if(searchValue4.toString() !== "" && OrderList[i]["VendorName"].toUpperCase().includes(searchValue4.toString().toUpperCase()) ){
        OrderList[i]["key"] = countNum
        countNum++
        tempObject.push(OrderList[i])
      }
      if(searchValue5Ref.current!.input?.value.toString() !== "" && OrderList[i]["DriverName"].toUpperCase().includes(searchValue5.toString().toUpperCase())){
        OrderList[i]["key"] = countNum
        countNum++
        tempObject.push(OrderList[i])
      }
      if(searchValue6Ref.current!.input?.value.toString() !== "" && OrderList[i]["AssignVehicleID"].toUpperCase().includes(searchValue6.toString().toUpperCase())){
        OrderList[i]["key"] = countNum
        countNum++
        tempObject.push(OrderList[i])
      }
    }
    SetOrderSearchedList(tempObject)
  }else{
    setSearchValue1("")
    setSearchValue2("")
    setSearchValue3("")
    setSearchValue4("")
    setSearchValue5("")
    setSearchValue6("")
    for(var i = 0; i<listSize; i++){
        OrderList[i]["key"] = i
    }
    SetOrderSearchedList(OrderList)
  }
}
const tabButton = (index,type,showname)=>{
  return(
    <Col span={2}>
      <div style={{height:(tabHeight - 2)}}>
        <Button size="small" style={{background:"#1677ff",width:"100%",height:"100%"}} type="primary" onClick={()=>{
        SetTabIndex(index);
        SetOrderType(type);
        localStorage.setItem("webconsoleOrderType",type)
        SetLoading(true)
        GetOrder(localStorage.getItem('DCHSelectedOrderSummaryDate'),type)
        }}>{showname}</Button>
      </div>
      <div style={{height:2,background:((TabIndex===index)?"#FF0000":"#FFFFFF")}}>
        
      </div>

    </Col>
  )
}
const search1 = useMemo(()=>{return(<Input ref={searchValue1Ref} style={{width:'16.2%',marginLeft:'0%'}} value={searchValue1} onPressEnter={()=>{searchFunction(OrderList)}} onClick={()=>{setSearchValue2("");setSearchValue3("");setSearchValue4("");setSearchValue5("");setSearchValue6("");}} onChange={(e)=>{setSearchValue1(e.target.value)}} placeholder="Departure" />)},[searchValue1])
const search2 = useMemo(()=>{return(<Input ref={searchValue2Ref} style={{width:'16.2%',marginLeft:'0.5%'}} value={searchValue2} onPressEnter={()=>{searchFunction(OrderList)}} onClick={()=>{setSearchValue1("");setSearchValue3("");setSearchValue4("");setSearchValue5("");setSearchValue6("");}} onChange={(e)=>{setSearchValue2(e.target.value)}} placeholder="Arrival" />);},[searchValue2])
// const search3 = useMemo(()=>{return(<Input ref={searchValue3Ref} style={{width:'16.2%',marginLeft:'0.5%'}} value={searchValue3} onPressEnter={()=>{searchFunction(OrderList)}} onClick={()=>{setSearchValue1("");setSearchValue2("");setSearchValue4("");setSearchValue5("");setSearchValue6("");}} onChange={(e)=>{setSearchValue3(e.target.value)}} placeholder="Service provider" />);},[searchValue3])
// const search4 = useMemo(()=>{return(<Input ref={searchValue4Ref} style={{width:'16.2%',marginLeft:'0.5%'}} value={searchValue4} onPressEnter={()=>{searchFunction(OrderList)}} onClick={()=>{setSearchValue1("");setSearchValue2("");setSearchValue3("");setSearchValue5("");setSearchValue6("");}} onChange={(e)=>{setSearchValue4(e.target.value)}} placeholder="Vendor name" />);},[searchValue4])
const search3 = useMemo(()=>{return(
  <AutoComplete disabled={false} 
  //defaultValue={OrderDetailPageStatus.FlightNo}
  value={searchValue3}
  options={
      ServiceProviderList
  }
  style={{width:'16.2%',marginLeft:'0.5%'}}
  onSelect={(e)=>{
    setSearchValue3(e)
  }}
  onChange={(e)=>{
    setSearchValue3(e)
  }}
  filterOption={(inputValue, option) =>
      option!.label.toString().toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
      }
  placeholder="Service provider"
  onClick={()=>{setSearchValue1("");setSearchValue2("");setSearchValue4("");setSearchValue5("");setSearchValue6("");}} 
/>
);},[searchValue3])
const search4 = useMemo(()=>{return(
  <AutoComplete disabled={false} 
  //defaultValue={OrderDetailPageStatus.FlightNo}
  value={searchValue4}
  options={
      ServiceVenderList
  }
  style={{width:'16.2%',marginLeft:'0.5%'}}
  onSelect={(e)=>{
    setSearchValue4(e)
  }}
  onChange={(e)=>{
    setSearchValue4(e)
  }}
  filterOption={(inputValue, option) =>
      option!.label.toString().toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
      }
  placeholder="Vendor Name"
  onClick={()=>{setSearchValue1("");setSearchValue2("");setSearchValue3("");setSearchValue5("");setSearchValue6("");}} 
/>
);},[searchValue4,ServiceVenderList])
const search5 = useMemo(()=>{return(<Input ref={searchValue5Ref} style={{width:'16.2%',marginLeft:'0.5%'}} value={searchValue5} onPressEnter={()=>{searchFunction(OrderList)}} onClick={()=>{setSearchValue1("");setSearchValue2("");setSearchValue3("");setSearchValue4("");setSearchValue6("");}} onChange={(e)=>{setSearchValue5(e.target.value)}} placeholder="Driver" />);},[searchValue5])
const search6 = useMemo(()=>{return(<Input ref={searchValue6Ref} style={{width:'16.2%',marginLeft:'0.5%'}} value={searchValue6} onPressEnter={()=>{searchFunction(OrderList)}} onClick={()=>{setSearchValue1("");setSearchValue2("");setSearchValue3("");setSearchValue4("");setSearchValue5("");}} onChange={(e)=>{setSearchValue6(e.target.value)}} placeholder="Car plate" />);},[searchValue6])
let navigate = useNavigate();
  return (
    <div className='content' style={{minWidth:1440,height:(maxModalSize - headerHeight),minHeight:910,padding:'0vh'}}>
    {Header(navigate)}
    <div style={{background:'#1677ff',minWidth:1440,height:(maxModalSize - headerHeight - (paddingValue*2)),minHeight:840,paddingTop:5,paddingLeft:5,paddingRight:5,paddingBottom:5}}>
    <div className='commonContent' style={{background:'#ffffff',height:(maxModalSize - headerHeight - tabHeight - (paddingValue*2) - 5),minHeight:840,minWidth:1440,padding:'0vh',overflow:"hidden"}}>
      <Spin size="large" spinning={loading} tip="Loading...">
      <Row style={{height:tabHeight,background:"#1677ff"}}>
          {/*tabButton("0","","All")*/}
          {localStorage.getItem(`DCHOrderType`)?.substring(0,1) === "1"?tabButton("1","Outstanding","Outstanding"):null}
          {localStorage.getItem(`DCHOrderType`)?.substring(1,2) === "1"?tabButton("2","In-Progress","In Progress"):null}
          {localStorage.getItem(`DCHOrderType`)?.substring(2,3) === "1"?tabButton("3","Complete","Complete"):null}
          {localStorage.getItem(`DCHOrderType`)?.substring(3,4) === "1"?tabButton("4","Noshow","No-Show"):null}
          {localStorage.getItem(`DCHOrderType`)?.substring(4,5) === "1"?tabButton("5","Cancel","Cancelled"):null}
          {localStorage.getItem(`DCHOrderType`)?.substring(5,6) === "1"?tabButton("6","Uploaded","Uploaded"):null}
          {localStorage.getItem(`DCHOrderType`)?.substring(6,7) === "1"?tabButton("7","Departure","Departure"):null}
          {localStorage.getItem(`DCHOrderType`)?.substring(7,8) === "1"?tabButton("8","Arrival","Arrival"):null}
    </Row>
        <Row style={{background:"#C3D698"}}>
        <Col span={17}>
          <Text style={{fontSize:22,fontWeight:"bold",marginLeft:10}}>{SelectedDate}</Text>
        </Col>
        <Col span={1}>
          <Button type="primary" 
            style={{marginTop:2,width:"96%"}}
            onClick={()=>{
              var DateObject = new Date(localStorage.getItem('DCHSelectedOrderSummaryDate')!);
              DateObject = new Date(DateObject.getTime() - (24*60*60*1000))
              if((DateObject.getTime() )<new Date(TolocalDateString(new Date().toISOString()).substring(0,10)).getTime()){
                if(localStorage.getItem(`DCHOrderYesterday`) === "true"){
                  localStorage.setItem("DCHSelectedOrderSummaryDate",DateObject.toISOString().substring(0,10))
                  SetSelectedDate(DateObject.toISOString().substring(0,10));
                  GetOrder(DateObject.toISOString().substring(0,10),OrderType);
                }else{
                  oneButtonConfirmDialog("Error","No permission for checking past order",()=>{})
                }
              }else{
                localStorage.setItem("DCHSelectedOrderSummaryDate",DateObject.toISOString().substring(0,10))
                SetSelectedDate(DateObject.toISOString().substring(0,10));
                GetOrder(DateObject.toISOString().substring(0,10),OrderType);
              }
            }}>
            {"<<"}
          </Button>
        </Col>
        <Col span={2}>
          <Button type="primary" 
            style={{marginTop:2,width:"96%"}}
            onClick={()=>{
              SetSelectedDate(TolocalDateString(new Date().toISOString()).substring(0,10));
              localStorage.setItem("DCHSelectedOrderSummaryDate",TolocalDateString(new Date().toISOString()).substring(0,10))
              GetOrder(TolocalDateString(new Date().toISOString()).substring(0,10),OrderType);
            }}>
            {"Today"}
          </Button>
        </Col>
        <Col span={1}>
          <Button type="primary" 
            style={{marginTop:2,width:"96%"}}
            onClick={()=>{
              var DateObject = new Date(localStorage.getItem('DCHSelectedOrderSummaryDate')!);
              DateObject = new Date(DateObject.getTime() + (24*60*60*1000))

              if((DateObject.getTime() )<new Date(TolocalDateString(new Date().toISOString()).substring(0,10)).getTime()){
                if(localStorage.getItem(`DCHOrderYesterday`) === "true"){
                  localStorage.setItem("DCHSelectedOrderSummaryDate",DateObject.toISOString().substring(0,10))
                  SetSelectedDate(DateObject.toISOString().substring(0,10));
                  GetOrder(DateObject.toISOString().substring(0,10),OrderType);
                }else{
                  oneButtonConfirmDialog("Error","No permission for checking past order",()=>{})
                }
              }else{
                localStorage.setItem("DCHSelectedOrderSummaryDate",DateObject.toISOString().substring(0,10))
                SetSelectedDate(DateObject.toISOString().substring(0,10));
                GetOrder(DateObject.toISOString().substring(0,10),OrderType);
              }
            }}>
            {">>"}
          </Button>
        </Col>
        <Col span={3}>
          <DatePicker value={dayjs(`${
                      localStorage.getItem('DCHSelectedOrderSummaryDate')
                  }`,
                  'YYYY-MM-DD')
                  } onChange={(e)=>{
                    if(e !== null){
                      if((e!.valueOf()) < new Date(TolocalDateString(new Date().toISOString()).substring(0,10)).getTime()){
                        if(localStorage.getItem(`DCHOrderYesterday`) === "true"){
                          localStorage.setItem("DCHSelectedOrderSummaryDate",(e !== null)?TolocalDateString(e?.toISOString()).substring(0,10):TolocalDateString(new Date().toISOString()).substring(0,10));
                          SetSelectedDate((e !== null)?TolocalDateString(e?.toISOString()).substring(0,10):TolocalDateString(new Date().toISOString()).substring(0,10));
                          GetOrder((e !== null)?TolocalDateString(e?.toISOString()).substring(0,10):TolocalDateString(new Date().toISOString()).substring(0,10),OrderType);
                        }else{
                          oneButtonConfirmDialog("Error","No permission for checking past order",()=>{})
                        }
                      }else{
                        localStorage.setItem("DCHSelectedOrderSummaryDate",(e !== null)?TolocalDateString(e?.toISOString()).substring(0,10):TolocalDateString(new Date().toISOString()).substring(0,10));
                        SetSelectedDate((e !== null)?TolocalDateString(e?.toISOString()).substring(0,10):TolocalDateString(new Date().toISOString()).substring(0,10));
                        GetOrder((e !== null)?TolocalDateString(e?.toISOString()).substring(0,10):TolocalDateString(new Date().toISOString()).substring(0,10),OrderType);
                      }
                    }
                  }} style={{ width: "96%",marginLeft:10,marginRight:10,marginTop:3,marginBottom:3,float:"right"}}            
                  />
        </Col>
        </Row>
        <Row style={{paddingLeft:10,paddingRight:10,height:50,marginTop:5,marginBottom:0}}>
              <Col span={0}></Col>
              <Col span={20}>
                <Button type="primary" 
                    style={{width:150}}
                    onClick={()=>{
                      confirmDialog2("Upload Selected Order?","","Yes","No",()=>{
                        const listSize = selectedRowKeys.length
                        var OrderNo = ""
                        for(var i = 0; i<listSize; i++){
                          if(i !== (listSize-1)){
                            OrderNo = OrderNo + OrderSearchedList[parseInt(selectedRowKeys[i])]["OrderNo"] + ","
                          }else{
                            OrderNo = OrderNo + OrderSearchedList[parseInt(selectedRowKeys[i])]["OrderNo"]
                          }
                          //ConsoleLog(OrderNo)
                          UpdateOrderReadyToUpload(OrderNo)
                        }
                      },()=>{})

                    }}>
                    Upload Selected
                </Button>
                {}{}{search3}{search4}{search5}{search6}</Col>
              <Col span={0}></Col>
              <Col span={2}>
                <Button type="primary" 
                    style={{width:"98%"}}
                    onClick={()=>{
                      setSearchValue1("")
                      setSearchValue2("")
                      setSearchValue3("")
                      setSearchValue4("")
                      setSearchValue5("")
                      setSearchValue6("")
                      const listSize = OrderList.length
                      for(var i = 0; i<listSize; i++){
                        OrderList[i]["key"] = ""+i
                      }
                      SetOrderSearchedList(OrderList)
                      setSelectedRowKeys([])
                    }}>
                    Clear
                </Button>
              </Col>
              <Col span={2}>
              <Button type="primary" 
                    style={{width:"98%"}}
                    onClick={()=>{
                      searchFunction(OrderList)
                      setSelectedRowKeys([])
                    }}>
                    Search
                </Button>
              </Col>
        </Row>
        <Row>
          {tableList}
        </Row>
      </Spin>
      {dialogAssignDriver}
      {dialogAudit}
    </div>
    </div>
    </div>
  );
}//
